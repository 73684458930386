import React from 'react'
import { useQuery, useMutation } from 'react-query'
import { useSelector } from 'react-redux'

import auth from 'api'
import { addToCondition } from 'helpers/api'

const CONTROLLER = `individual-teacher/teacher/settings`

const QueryKeys = {
  all: `individualTeacherSettings`,
}

const MutationKeys = {}

export const getIndTeacherSettings = async ({ queryKey }) => {
  const [, teacherId] = queryKey
  let condition = ''
  condition += '?per_page=0'
  condition = addToCondition(condition, teacherId, 'teacher_id')
  const { data } = await auth({
    method: 'get',
    url: `auth/${CONTROLLER}${condition}`,
  })
  return data
}
export const useIndTeacherSettings = (
  teacherId,
  { queryOptions = {} } = {}
) => {
  return useQuery([QueryKeys.all, teacherId], getIndTeacherSettings, {
    ...queryOptions,
  })
}

export const createIndTeacherSetting = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/${CONTROLLER}`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useCreateIndTeacherSetting = ({ queryOptions } = {}) => {
  return useMutation(createIndTeacherSetting, {
    ...queryOptions,
  })
}

export const updateIndTeacherSetting = async ({ id, payload }) => {
  const { data } = await auth({
    method: 'put',
    url: `auth/${CONTROLLER}/${id}`,
    data: {
      ...payload,
    },
  })
  return data
}

export const useUpdateIndTeacherSetting = ({ queryOptions } = {}) => {
  return useMutation(updateIndTeacherSetting, {
    ...queryOptions,
  })
}

export const useIndTeacherSetting = (id, queryOptions = {}) => {
  const currentSubSchool = useSelector((state) => state.auth.currentSubSchool)
  const SCHOOL_TYPE_2 = currentSubSchool?.type_id === 2
  const currentSubSchoolID = SCHOOL_TYPE_2
    ? currentSubSchool?.user_id
    : currentSubSchool?.id

  return useIndTeacherSettings(currentSubSchoolID, {
    queryOptions: {
      select: React.useCallback(
        (data) => {
          return data.items.find((setting) => setting.setting_id === id)
        },
        [id]
      ),
      ...queryOptions,
    },
  })
}

const IndTeacherSettings = {
  QueryKeys,
  MutationKeys,
  fns: {
    getIndTeacherSettings,
    createIndTeacherSetting,
    updateIndTeacherSetting,
  },
  hooks: {
    useIndTeacherSettings,
    useIndTeacherSetting,
    useCreateIndTeacherSetting,
    useUpdateIndTeacherSetting,
  },
}
export default IndTeacherSettings
