import { useQuery, useMutation } from 'react-query'
import auth from 'api'
import { parseFilters, parsePage, generateFilter } from 'helpers/api'
import { uniq } from 'lodash'
import { getPackages } from 'api/Package/Packages/queries'

export const getUserMissions = async ({ queryKey }) => {
  const [, page, { filters = {}, includeModels = [] } = {}] = queryKey
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)

  const { data: userMissions } = await auth({
    method: 'get',
    url: `auth/gamification/user/missions${condition}`,
  })

  let packages = userMissions?.items.map(
    (mission) => mission.Mission.package_id
  )
  let allPackagesData
  if (includeModels?.includes('Packages')) {
    if (packages.length) {
      let uniquePackageIds = uniq(packages)
      const packageFilters = generateFilter([], uniquePackageIds, 'id')
      allPackagesData = await getPackages({
        queryKey: [
          null,
          null,
          {
            filters: packageFilters,
          },
        ],
      })
    }
  }
  let classMissionsWithData = userMissions

  classMissionsWithData = {
    ...userMissions,
    items: userMissions.items.reduce((acc, clsMission) => {
      let mission = { ...clsMission }
      if (includeModels?.includes('Packages') && allPackagesData.items.length) {
        let idx = allPackagesData.items.findIndex(
          (pData) => pData.id === mission.Mission.package_id
        )
        if (idx !== -1) {
          mission.Package = allPackagesData.items[idx]
        }
      }

      return [...acc, mission]
    }, []),
  }

  return classMissionsWithData
}

export const useUserMissions = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'userMissions',
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    getUserMissions,
    {
      keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 60,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}
// -----------------------------------
export const openUserMission = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/gamification/user/missions`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useOpenUserMission = ({ queryOptions } = {}) => {
  return useMutation(openUserMission, {
    ...queryOptions,
  })
}
