import React from 'react'
import { useMutation, useQuery } from 'react-query'

import auth from 'api'

export const getStaticContent = async () => {
  const { data } = await auth({
    method: 'get',
    url: `/guest/user/static_content`,
  })
  return data
}

export const useStaticContent = ({ queryOptions } = {}) => {
  return useQuery(['staticContent'], getStaticContent, {
    cacheTime: Infinity,
    staleTime: Infinity,
    ...queryOptions,
  })
}

// -----------------------------------

export const useStaticContentSingle = (id) => {
  return useStaticContent({
    queryOptions: {
      select: React.useCallback(
        (data) => {
          return data.find((content) => content.id === id)
        },
        [id]
      ),
    },
  })
}
// export const getStaticContentSingle = async ({ queryKey }) => {
//   const [, id] = queryKey
//   const { data } = await auth({
//     method: 'get',
//     url: `/guest/user/static_content/${id}`,
//   })

//   return data
// }
// export const useStaticContentSingle = (
//   id,
//   { queryFilters = {}, queryOptions = {} } = {}
// ) => {
//   return useQuery(
//     ['singleStaticContent', id, { ...queryFilters }],
//     getStaticContentSingle,
//     {
//       cacheTime: Infinity,
//       staleTime: Infinity,
//       ...queryOptions,
//     }
//   )
// }
// -----------------------------------
export const createStaticContent = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `/auth/user/static_content`,
    data: {
      static_content: {
        ...payload,
      },
    },
  })
  return data
}
export const useCreateStaticContent = ({ queryOptions } = {}) => {
  return useMutation(createStaticContent, {
    ...queryOptions,
  })
}
// -----------------------------------
export const updateStaticContent = async ({ id, payload }) => {
  const { data } = await auth({
    method: 'put',
    url: `/auth/user/static_content/${id}`,
    data: {
      static_content: {
        ...payload,
      },
    },
  })
  return data
}
export const useUpdateStaticContent = ({ queryOptions } = {}) => {
  return useMutation(updateStaticContent, {
    ...queryOptions,
  })
}
// -----------------------------------
export const deleteStaticContent = async (id) => {
  await auth({
    method: 'delete',
    url: `/auth/user/static_content/${id}`,
  })
}
export const useDeleteStaticContent = ({ queryOptions } = {}) => {
  return useMutation(deleteStaticContent, {
    ...queryOptions,
  })
}
