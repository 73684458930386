import { createTheme } from '@material-ui/core/styles'

const digicyan = '#33cccc'
const digidarkblue = '#3e5266'
const digipurple = '#a000a5'
const digired = '#b00020'
const digigreen = '#61be2d'
const digiblue = '#292d94'
const digigrey = '#3d5265'
const digilightgrey = '#c4c4c4'
const digiothergrey = '#72889F'
const digifooter1 = '#E3E3E3'
const digimoss = '#007E7D'
const digimossvariant = '#005B54'
const digiwarning = '#FFC208'
const digiwhite = '#FFFFFF'
// const digiwarning = '#ffc107'

const textprimary = '#1e2b39'
const textsecondary = '#1e2b39'

export const primary = createTheme({
  palette: {
    primary: { main: digimoss, contrastText: '#fff' },
  },
})
export const secondary = createTheme({
  palette: {
    primary: { main: digidarkblue },
  },
})
export const green = createTheme({
  palette: {
    primary: { main: digigreen, contrastText: '#fff' },
  },
})
export const red = createTheme({
  palette: {
    primary: { main: digired },
  },
})
export const purple = createTheme({
  palette: {
    primary: { main: digipurple },
  },
})

export const blue = createTheme({
  palette: {
    primary: { main: digiblue },
  },
})

export const cyan = createTheme({
  palette: {
    primary: { main: digicyan, contrastText: '#fff' },
  },
})
export const text = createTheme({
  palette: {
    primary: { main: textprimary },
  },
})
export const grey = createTheme({
  palette: {
    primary: { main: digilightgrey },
  },
})

export const warning = createTheme({
  palette: {
    primary: { main: digiwarning },
  },
})
export const white = createTheme({
  palette: {
    primary: { main: digiwhite, contrastText: '#fff' },
  },
})

export default createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 12,
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: 24,
        // color: `${digigrey}`,
      },
    },
    MuiButton: {
      root: {
        // boxShadow: `${baseShadow}`,
        // border: '3px solid red',
        // fontSize: 40,
        // outlined: {
        //   border: '5px solid red',
        // },
        // '&	.MuiButton-outlined': {
        //   border: '3px solid black',
        // },
      },
      outlined: {
        border: '3px solid red',
      },
      // ' 	.MuiButton-outlined'
    },
    // '&	.MuiButton-outlined': {
    //   border: '3px solid black',
    // },
  },
  typography: {
    h1: {
      fontSize: 54,
      fontWeight: 'bold',
      fontFamily: 'Roboto Slab',
      color: '#FFFFFF',
    },
    h2: {
      fontSize: 36,
      // fontWeight: 'lighter',
      fontWeight: 300,
      fontFamily: 'Roboto',
      color: '#000000',
    },
    h3: {
      fontSize: 34,
      fontWeight: 'bold',
      fontFamily: 'Roboto Slab',
      color: '#007E7D',
    },
    h4: {
      fontSize: 26,
      fontWeight: 'normal',
      fontFamily: 'Roboto Slab',
      color: '#000000',
    },
    h5: {
      fontSize: 22,
      fontWeight: 'normal',
      fontFamily: 'Roboto',
      color: '#000000',
    },
    h6: {
      fontSize: 20,
      fontWeight: 'normal',
      fontFamily: 'Roboto Slab',
      color: '#007E7D',
    },
    h7: {
      fontSize: 18,
      fontWeight: 'normal',
      fontFamily: 'Roboto Slab',
      color: '#000000',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 'normal',
      fontFamily: 'Roboto',
      color: '#000000',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: '500',
      fontFamily: 'Roboto',
      color: '#000000',
    },
    subtitle3: {
      fontSize: 14,
      // fontWeight: 'lighter',
      fontWeight: 300,
      fontFamily: 'Roboto',
      color: '#000000',
    },
    body1: {
      fontSize: 16,
      fontWeight: 'normal',
      fontFamily: 'Roboto',
      color: '#000000',
    },
    body2: {
      fontSize: 14,
      fontWeight: '500',
      fontFamily: 'Roboto',
      color: '#000000',
    },
    caption: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      color: '#000000',
      fontSize: 12,
    },
    overline: {
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      color: '#A000A5',
      fontSize: 12,
      textTransform: 'uppercase',
    },

    fontFamily: ['Roboto', 'Roboto Slab'].join(','),
    mainbutton: {
      // width: '4rem',
      minWidth: '10rem',
      borderRadius: '50px',
      // minHeight: '2rem',
      padding: `0.5rem 1.2rem`,
      color: 'default',
    },
    mainbuttonsmall: {
      //   width: theme.spacing(4),
      //   minWidth: theme.spacing(20),
      //   borderRadius: theme.spacing(4),
      //   padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
      //   margin: theme.spacing(2, 0),
      // minWidth: '4rem',
      minWidth: '8rem',
      borderRadius: '50px',
      minHeight: '1.8rem',
      // padding: `0.5rem 1rem`,
      padding: `0.6rem 1.8rem`,
    },
    mainlogo: {
      top: `calc(0% - 50px)`,
      left: `calc(50% - 50px)`,
      width: '100px',
      height: '100px',
      color: 'white',
      backgroundColor: `${digicyan}`,
      // boxShadow: `${baseShadow}`,
    },
    bottomtext: {
      fontWeight: 'bold',
      fontSize: 18,
      color: `${digidarkblue}`,
    },
    // bottomlink: {
    //   fontSize: 12,
    //   color: `${digidarkblue}`,
    // },
    inputicon: {
      // margin: '6px 0',
      marginBottom: 28, //30
      fontSize: 24,
      // color: `${digigrey}`,
    },
    gridIcon: {
      marginBottom: 22, //30
      // fontSize: 24,
      // color: `${digigrey}`,
    },
  },
  palette: {
    common: {
      digicyan: `${digicyan}`,
      digidarkblue: `${digidarkblue}`,
      digipurple: `${digipurple}`,
      digired: `${digired}`,
      digigreen: `${digigreen}`,
      digiblue: `${digiblue}`,
      digigrey: `${digigrey}`,
      digiothergrey: `${digiothergrey}`,
      digilightgrey: `${digilightgrey}`,
      digimoss: `${digimoss}`,
      digimossvariant: `${digimossvariant}`,
      textprimary: `${textprimary}`,
      textsecondary: `${textsecondary}`,
      digifooter1: `${digifooter1}`,
      digiwarning: `${digiwarning}`,
    },
    error: {
      main: `${digired}`,
    },
    primary: {
      main: `${digimoss}`,
      contrastText: '#fff',
    },
    secondary: {
      main: `${digidarkblue}`,
    },
    tertiary: {
      main: `${digipurple}`,
    },
    quaternary: {
      main: `${digired}`,
    },
    quinary: {
      main: `${digimossvariant}`,
    },
    cyan: {
      main: `${digicyan}`,
      contrastText: '#fff',
    },
  },
  //   status: {
  //     danger: '#e53e3e',
  //   },
})
