import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  Typography,
  Grid,
  Avatar,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@material-ui/core/'
import { VisibilityOff, Visibility } from '@material-ui/icons'
import { useForm, Controller } from 'react-hook-form'
import { useUpdateUser } from 'api/User/Users/queries'
import Saita from 'assets/img/saita.png'
import Button from 'ui/Button'
import useNotification from 'hooks/useNotification'
import useSetState from 'hooks/useSetState'
import { PASSWORD_PATTERN } from 'constants/roles'
import parseError from 'helpers/errorHelpers'
import { useDispatch, useSelector } from 'react-redux'
import useTitle from 'hooks/useTitle'
import { useQueryClient } from 'react-query'
import { useLogout } from 'api/User/Users/queries'
import { resetApp } from 'store/auth/actions'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4, 0),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(6),
    },
  },
  wrapper: {
    maxWidth: 700,
  },
  paper: {
    padding: theme.spacing(6, 12),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 4),
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(4, 8),
    },
    flexGrow: 1,
    position: 'relative',
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: theme.spacing(2, 0),
  },

  title: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    paddingTop: theme.spacing(7),
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  logo2: {
    position: 'absolute',
    backgroundColor: '#33cbcb',
    ...theme.typography.mainlogo,
    color: theme.palette.common.white,
  },
  icon: { fontSize: 70, color: 'white' },
  button: {
    ...theme.typography.mainbutton,
  },
}))

function PasswordChange() {
  const classes = useStyles()
  const { addNotification } = useNotification()

  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const { resetTitle } = useTitle()
  const logoutMutation = useLogout({
    queryOptions: {
      onSuccess: () => {
        resetTitle()
        dispatch(resetApp())
        queryClient.cancelQueries()
        queryClient.removeQueries()
        // history.push('/')
      },
      onError: (error) => {
        addNotification({
          type: 'error',
          message: parseError(error, 'Error logging out'),
        })
      },
    },
  })

  const userId = useSelector((state) => state.auth?.user?.id)
  const fullName = useSelector((state) => state.auth?.user?.full_name)

  const [showPassword, setShowPassword] = useSetState({
    password: '',
    confirmPassword: '',
  })

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
      full_name: fullName,
    },
  })
  const IS_REQUIRED = 'Field is required'

  let NOT_VALID_PASSWORD =
    'Minimum requirements: 6 characters, 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character.'

  let NO_MATCH = "Passwords don't match"

  const handleShowPassword = (key) => {
    setShowPassword((old) => ({ ...old, [key]: !old[key] }))
  }

  const updatePassword = useUpdateUser({
    queryOptions: {
      onError: (error) => {
        addNotification({
          type: 'error',
          message: parseError(error, `Error updating Password`),
        })
      },
      onSuccess: async () => {
        addNotification({
          type: 'success',
          message: 'Successfully updated Password, please login again!',
        })
        reset()
        logoutMutation.mutate()
      },
    },
  })

  const onSubmit = (data) => {
    updatePassword.mutate({
      id: userId,
      payload: {
        password: data.password,
        full_name: data.full_name.trim(),
        reset_password: false,
      },
    })
  }

  const handleLogout = () => {
    logoutMutation.mutate()
  }

  return (
    <>
      <form className={clsx(classes.root)} onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="column"
          spacing={2}
          justifyContent="center"
          className={classes.wrapper}
        >
          <Grid item xs={12} className={classes.center}>
            <Paper className={clsx(classes.paper)} elevation={3}>
              <Avatar className={classes.logo2}>
                <img src={Saita} className={classes.icon} />
              </Avatar>
              <Typography
                component="div"
                variant="h6"
                color="primary"
                className={classes.title}
              >
                Enter a new Password
              </Typography>
              <Grid container spacing={2} className={clsx(classes.form1)}>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    component="div"
                    variant="body1"
                    className={classes.subtitle}
                  >
                    For security purposes you are required to enter a new
                    password
                    <br />
                    Please enter all required fields
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: IS_REQUIRED,
                      pattern: {
                        value: PASSWORD_PATTERN,
                        message: NOT_VALID_PASSWORD,
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        type={showPassword.password ? 'text' : 'password'}
                        // variant="outlined"
                        label="New Password*"
                        error={!!errors?.password}
                        helperText={errors?.password?.message}
                        inputProps={{
                          autoComplete: 'new-password',
                          form: {
                            autoComplete: 'off',
                          },
                        }}
                        {...field}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                color={
                                  showPassword.password
                                    ? 'primary'
                                    : 'secondary'
                                }
                                onClick={() => handleShowPassword('password')}
                              >
                                {showPassword.password ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    rules={{
                      required: IS_REQUIRED,
                      pattern: {
                        value: PASSWORD_PATTERN,
                        message: NOT_VALID_PASSWORD,
                      },
                      validate: {
                        matchesPreviousPassword: (value) => {
                          const { password } = getValues()
                          return password === value || NO_MATCH
                        },
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        type={
                          showPassword.confirmPassword ? 'text' : 'password'
                        }
                        // variant="outlined"
                        label="Confirm Password*"
                        error={!!errors?.confirmPassword}
                        helperText={errors?.confirmPassword?.message}
                        inputProps={{
                          autoComplete: 'new-password',
                          form: {
                            autoComplete: 'off',
                          },
                        }}
                        {...field}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                color={
                                  showPassword.confirmPassword
                                    ? 'primary'
                                    : 'secondary'
                                }
                                onClick={() =>
                                  handleShowPassword('confirmPassword')
                                }
                              >
                                {showPassword.confirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                {!fullName ? (
                  <Grid item xs={12}>
                    <Controller
                      name="full_name"
                      control={control}
                      rules={{ required: IS_REQUIRED }}
                      render={({ field }) => (
                        <TextField
                          fullWidth
                          // variant="outlined"
                          label="Full Name*"
                          error={!!errors?.full_name}
                          helperText={errors?.full_name?.message}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                ) : null}

                <Grid
                  item
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="space-evenly"
                  style={{ marginTop: 30 }}
                >
                  <Grid item>
                    <Button
                      onClick={handleLogout}
                      className={classes.button}
                      variant="outlined"
                      color="secondary"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      // onClick={resetSteps}
                      className={clsx(classes.button)}
                    >
                      {updatePassword.isLoading ? (
                        <CircularProgress size={24} />
                      ) : (
                        'submit'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default PasswordChange
