import React, {
  createContext,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'

const USER_ROLE_KEY = 'digirole'

const acceptedUserRoles = [
  'Admin',
  'Master',
  'School Manager',
  'Teacher',
  'Individual Teacher',
  'Student',
  'Member',
  'Guardian',
  'Code Manager',
  'Editor',
  'Tester',
  'Moderator',
  'Sales',
  'Project Manager',
]

const getUserRole = () => {
  const storedRole = localStorage.getItem(USER_ROLE_KEY)
  if (storedRole && acceptedUserRoles.includes(storedRole)) {
    return storedRole
  }
}

const useUserRoleContextState = () => {
  const [role, setRole] = useState(null)

  // const { i18n } = useTranslation()

  useLayoutEffect(() => {
    const storedRole = getUserRole()
    // ?? i18n.role
    changeUserRole(storedRole)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeUserRole = useCallback(
    (role) => {
      localStorage.setItem(USER_ROLE_KEY, role)
      setRole(role)
      // i18n.changeUserRole(role)
    },
    []
    // [i18n]
  )

  return {
    role,
    actions: {
      changeUserRole,
    },
  }
}

const UserRoleContext = createContext()

UserRoleContext.displayName = 'UserRoleContext'

const UserRoleContextProvider = ({ children }) => {
  const state = useUserRoleContextState()
  return (
    <UserRoleContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </UserRoleContext.Provider>
  )
}

UserRoleContextProvider.propTypes = {
  children: PropTypes.node,
}

const useUserRoleContext = () => useContext(UserRoleContext)

export { UserRoleContext, UserRoleContextProvider, useUserRoleContext }
