import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/react'
import create from 'zustand'
import sentryMiddleware from 'sentry-zustand-middleware'
import { devtools, persist } from 'zustand/middleware'

import { reducer as AuthReducer } from './auth'
import { reducer as ReaderReducer } from './reader'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    const transformedState = {}
    if (state.auth.user) {
      transformedState.User = {
        isMasq: useMasqStore.getState().isMasq,
        ...state.auth.user,
        masqUser: useMasqStore.getState()?.user,
      }
    }
    if (state.auth.currentSubSchool) {
      transformedState.currentSubSchool = { ...state.auth.currentSubSchool }
    }
    if (state.auth.currentSchoolYear) {
      transformedState.currentSchoolYear = { ...state.auth.currentSchoolYear }
    }
    return transformedState
  },
})

const persistConfig = {
  // eslint-disable-next-line no-undef
  key: process.env.REACT_APP_STORAGE,
  storage,
}

const composedEnhancer = composeWithDevTools(
  // compose(applyMiddleware(thunk), sentryReduxEnhancer)
  applyMiddleware(thunk),
  sentryReduxEnhancer
)

const appReducer = combineReducers({
  auth: AuthReducer,
  reader: ReaderReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined
  }
  return appReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, composedEnhancer)
export const persistor = persistStore(store)

export default store

const stateTransformer = (state) => {
  return Object.fromEntries(
    Object.entries(state).filter(([key]) => typeof state[key] !== 'function')
  )
}

export const useMasqStore = create(
  devtools(
    persist(
      sentryMiddleware(
        (set) => ({
          isMasq: false,
          user: null,
          setMasq: (userId) => {
            set({ isMasq: true, user: userId }, false, 'masq/set')
          },
          undoMasq: () =>
            set({ isMasq: false, user: null }, false, 'masq/undo'),
        }),
        { stateTransformer: stateTransformer }
      ),
      { name: 'masq-storage' }
    ),
    { name: 'masq' }
  )
)
