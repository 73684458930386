import React from 'react'
import { useCountries } from 'api/User/Countries/queries'
import { useLanguages } from 'api/User/Languages/queries'
import { useStaticContent } from 'api/User/StaticContent/queries'
import Landing from 'components/Main'

function LandingPage() {
  useCountries()
  useLanguages()
  useStaticContent()
  return <Landing />
}

export default LandingPage

LandingPage.propTypes = {}
