const SET_URL = 'SET_URL'
const SET_ACTIVITY = 'SET_ACTIVITY'
const SET_TOC = 'SET_TOC'
const SET_SPINE = 'SET_SPINE'
const SET_PACKAGE = 'SET_PACKAGE'
const SET_READ_TYPE = 'SET_READ_TYPE'
const SET_ACTIVITIES = 'SET_ACTIVITIES'

export {
  SET_URL,
  SET_ACTIVITY,
  SET_TOC,
  SET_SPINE,
  SET_PACKAGE,
  SET_READ_TYPE,
  SET_ACTIVITIES,
}
