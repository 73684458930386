/* eslint-disable no-case-declarations */
import { useQuery, useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import auth from 'api'
import {
  addToCondition,
  parseFilters,
  parsePage,
  parseSearch,
} from 'helpers/api'
import { setUser, setAccessToken } from 'store/auth/actions'
import store from 'store'
const { dispatch } = store

// -----------------------------------
export const searchTableUsers = async ({ queryKey }) => {
  const [, page, { filters = [], searchModels = {} } = {}] = queryKey
  let usersSearch
  if (searchModels['Users']) {
    usersSearch = parseSearch('', searchModels, 'Users')
  }

  const foundUsers = await getUsers({
    queryKey: [
      null,
      page,
      {
        filters,
        search: usersSearch,
      },
    ],
  })

  return foundUsers
}
export const useSearchTableUsers = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'searchUsers',
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    searchTableUsers,
    {
      refetchOnWindowFocus: false,
      // keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 10,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}
// -----------------------------------
export const getUsers = async ({ queryKey }) => {
  const [, page, { filters = [], users = [], search } = {}] = queryKey
  const PER_PAGE = 100

  let usersWithData

  if (!users || PER_PAGE > users.length) {
    let condition = ''

    condition = parsePage(page, condition)
    condition = parseFilters(filters, condition)
    if (users?.length) {
      condition = addToCondition(condition, users, 'id')
    }

    if (search?.length) condition += `&${search}`

    let { data } = await auth({
      method: 'get',
      url: `auth/user/users${condition}`,
    })

    usersWithData = data
  } else {
    let totalPages = Math.ceil(users.length / PER_PAGE)
    let totalItems = 0

    for (let page = 0; page < totalPages; page++) {
      let condition = ''

      condition = parsePage(null, condition)
      condition = parseFilters(filters, condition)

      let usersSlicedArray = users.slice(
        page * PER_PAGE,
        page * PER_PAGE + PER_PAGE
      )

      if (usersSlicedArray?.length) {
        condition = addToCondition(condition, usersSlicedArray, 'id')
      }

      if (search?.length) condition += `&${search}`

      let { data } = await auth({
        method: 'get',
        url: `auth/user/users${condition}`,
      })
      totalItems = data.total_items + totalItems
      if (page === 0) {
        usersWithData = data
      } else {
        usersWithData.items = [...usersWithData.items, ...data.items]
      }
    }

    usersWithData.total_items = totalItems
    usersWithData.per_page = totalItems
    usersWithData.filter.id = users
  }

  return usersWithData
}

export const useUsers = (
  page,
  { queryFilters = { filters: [] }, queryOptions } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    ['users', paginationIsDisabled ? null : page + 1, { ...queryFilters }],
    getUsers,
    {
      keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 10,
      ...queryOptions,
    }
  )

  // React.useEffect(() => {
  //   if (data?.total_pages > data?.current_page && !paginationIsDisabled) {
  //     queryClient.prefetchQuery(
  //       ['users', page + 2, { ...queryFilters }],
  //       getUsers
  //     )
  //   }
  // }, [data, page, queryClient, paginationIsDisabled, queryFilters])

  return { data, ...rest }
}
// -----------------------------------
export const getUser = async ({ queryKey }) => {
  const [, id] = queryKey
  const { data: user } = await auth({
    method: 'get',
    url: `auth/user/users/${id}`,
  })

  return user
}
export const useUser = (id, { queryFilters = {}, queryOptions = {} } = {}) => {
  return useQuery(['user', id, { ...queryFilters }], getUser, {
    ...queryOptions,
  })
}
// -----------------------------------
export const searchUsers = async ({ filters }) => {
  let condition = ''
  condition = parseFilters(filters, condition)
  const { data } = await auth({
    method: 'get',
    url: `auth/user/users?per_page=0${condition}`,
  })
  return data
}
export const useSearchUsers = ({ queryOptions } = {}) => {
  return useMutation(searchUsers, {
    ...queryOptions,
  })
}
// -----------------------------------
export const getCurrentUser = async ({ queryKey }) => {
  const [, id] = queryKey
  const {
    data: { user },
  } = await auth({
    method: 'get',
    url: `auth/user/users/${id}`,
  })
  return user
}
export const useCurrentUser = ({ queryOptions = {} } = {}) => {
  let currentId = useSelector((state) => state.auth.user?.id)
  return useQuery(['currentUser', currentId], getCurrentUser, {
    enabled: !!currentId,
    staleTime: Infinity,
    cacheTime: Infinity,
    ...queryOptions,
  })
}
// -----------------------------------
export const signUpUser = async ({ reCaptchaToken, ...rest }) => {
  await auth({
    method: 'post',
    url: 'guest/user/users',
    data: {
      'g-recaptcha-response': reCaptchaToken,
      ...rest,
    },
  })
}
export const useSignUpUser = ({ queryOptions } = {}) => {
  return useMutation(signUpUser, {
    ...queryOptions,
  })
}
// ----------------------------------- FOR MASTER MANAGER ADMIN
export const createSchoolUser = async ({
  user,
  package: pkg,
  join,
  school,
  individual_teacher,
}) => {
  let payload = {}

  if (user) {
    payload.user = {
      ...user,
    }
  }
  if (pkg) {
    payload.package = {
      ...pkg,
    }
  }
  if (join) {
    payload.join = {
      ...join,
    }
  }
  if (school) {
    payload.school = {
      ...school,
    }
  }
  if (individual_teacher) {
    payload.individual_teacher = {
      ...individual_teacher,
    }
  }
  const { data } = await auth({
    method: 'post',
    url: `auth/user/users`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useCreateSchoolUser = ({ queryOptions } = {}) => {
  return useMutation(createSchoolUser, {
    ...queryOptions,
  })
}
// -----------------------------------
export const createUser = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/user/users`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useCreateUser = ({ queryOptions } = {}) => {
  return useMutation(createUser, {
    ...queryOptions,
  })
}
// missing create hook
// -----------------------------------
export const createSchoolUserBatch = async ({ user, join, batch }) => {
  let payload = {}
  if (batch) {
    payload.batch = {
      ...batch,
    }
  }

  if (user) {
    payload.user = {
      ...user,
    }
  }
  if (join) {
    payload.join = {
      ...join,
    }
  }
  const { data } = await auth({
    method: 'post',
    url: `auth/user/users/batch`,
    data: {
      ...payload,
    },
  })

  return data
}
export const useCreateSchoolUsersBatch = ({ queryOptions } = {}) => {
  return useMutation(createSchoolUserBatch, {
    ...queryOptions,
  })
}
// -----------------------------------
export const updateUser = async ({ id, payload }) => {
  const data = await auth({
    method: 'put',
    url: `auth/user/users/${id}`,
    data: {
      user: { ...payload },
    },
  })
  return data
}
export const useUpdateUser = ({ queryOptions } = {}) => {
  return useMutation(updateUser, {
    ...queryOptions,
  })
}
// -----------------------------------
export const confirmedDeleteUser = async ({ id }) => {
  const data = await auth({
    method: 'put',
    url: `auth/user/users/${id}`,
    data: {
      user: { deleted: true },
    },
  })
  return data
}
export const useConfirmedDeleteUser = ({ queryOptions } = {}) => {
  return useMutation(confirmedDeleteUser, {
    ...queryOptions,
  })
}
// -----------------------------------
export const deleteUser = async (id) => {
  const data = await auth({
    method: 'delete',
    url: `auth/user/users/${id}`,
  })
  return data
}
export const useDeleteUser = ({ queryOptions } = {}) => {
  return useMutation(deleteUser, {
    ...queryOptions,
  })
}
// -----------------------------------
export const updateUserRole = async ({ id, roleId }) => {
  const data = await auth({
    method: 'put',
    url: `auth/user/users/${id}/changerole`,
    data: {
      role_id: roleId,
    },
  })
  return data
}
export const useUpdateUserRole = ({ queryOptions } = {}) => {
  return useMutation(updateUserRole, {
    ...queryOptions,
  })
}
// -----------------------------------
export const validateUsername = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: 'guest/user/validate/username',
    data: {
      validate: {
        ...payload,
      },
    },
  })
  return data
}
export const useValidateUsername = ({ queryOptions } = {}) => {
  return useMutation(validateUsername, {
    ...queryOptions,
  })
}
// -----------------------------------
export const validateEmail = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: 'guest/user/validate/email',
    data: {
      validate: {
        ...payload,
      },
    },
  })
  return data
}
export const useValidateEmail = ({ queryOptions } = {}) => {
  return useMutation(validateEmail, {
    ...queryOptions,
  })
}
// ----------------------------------- LOGIN

export const loginUser = async ({ username, password, remember_me }) => {
  const {
    data: {
      user: { id: userId },
      auth: { access_token },
    },
  } = await auth({
    method: 'post',
    url: 'guest/user/auth/login',
    data: {
      credentials: {
        username: username.trim(),
        password: password.trim(),
        remember_me,
      },
    },
  })

  dispatch(setAccessToken(access_token))

  const { user } = await getUser({
    queryKey: [null, userId],
  })
  dispatch(setUser(user))

  return user
}

export const useLogin = ({ queryOptions } = {}) => {
  return useMutation(loginUser, {
    ...queryOptions,
  })
}
//----------------------FORGOT PASSWORD------------------
export const emailForgotPassword = async ({ reCaptchaToken, ...rest }) => {
  const { data } = await auth({
    method: 'post',
    url: `/guest/user/users/forgotpasswordemail`,
    data: {
      'g-recaptcha-response': reCaptchaToken,
      forgot: {
        ...rest,
      },
    },
  })
  return data
}

export const useEmailForgotPassword = ({ queryOptions } = {}) => {
  return useMutation(emailForgotPassword, {
    ...queryOptions,
  })
}

export const validateForgotWithActivationCode = async ({
  reCaptchaToken,
  ...rest
}) => {
  const { data } = await auth({
    method: 'post',
    url: `/guest/user/users/forgotpassword`,
    data: {
      'g-recaptcha-response': reCaptchaToken,
      forgot: {
        ...rest,
      },
    },
  })
  return data
}

export const useValidateForgotWithActivationCode = ({ queryOptions } = {}) => {
  return useMutation(validateForgotWithActivationCode, {
    ...queryOptions,
  })
}

export const updateForgotPassword = async ({ reCaptchaToken, ...rest }) => {
  const { data } = await auth({
    method: 'put',
    url: `/guest/user/users/forgotpassword`,
    data: {
      'g-recaptcha-response': reCaptchaToken,
      forgot: {
        ...rest,
      },
    },
  })
  return data
}

export const useUpdateForgotPassword = ({ queryOptions } = {}) => {
  return useMutation(updateForgotPassword, {
    ...queryOptions,
  })
}

// -----------------------------------
export const getActiveUsers = async ({ queryKey }) => {
  const [, page, { filters = [] } = {}] = queryKey

  let condition = ''
  let date = new Date()
  let m = date.getMonth()
  date.setMonth(date.getMonth() - 1)

  if (date.getMonth() == m) date.setDate(0)
  date.setHours(0, 0, 0, 0)

  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)

  condition = addToCondition(
    condition,
    Math.floor(date.getTime() / 1000),
    'created[gte]'
  )

  let { data } = await auth({
    method: 'get',
    url: `auth/user/sessions${condition}`,
  })

  return data
}

export const useActiveUsers = (
  page,
  { queryFilters = { filters: [] }, queryOptions } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'activeUsers',
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    getActiveUsers,
    {
      keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 10,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}

//-------------------------------------------------
export const masquerade = async (userId) => {
  const { data } = await auth({
    method: 'post',
    url: `/auth/user/auth/masquerade`,
    data: {
      user_id: userId,
    },
  })
  return data
}

export const useMasquerade = ({ queryOptions } = {}) => {
  return useMutation(masquerade, {
    ...queryOptions,
  })
}
//-------------------------------------------------
export const refreshToken = async () => {
  const { data } = await auth({
    method: 'get',
    url: '/guest/user/auth/refresh',
  })
  return data
}

export const useRefreshToken = ({ queryOptions } = {}) => {
  return useMutation(refreshToken, {
    ...queryOptions,
  })
}

//-------------------------------------------------
export const logout = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: '/auth/user/auth/logout',
    data: { ...payload },
  })
  return data
}

export const useLogout = ({ queryOptions } = {}) => {
  return useMutation(logout, {
    ...queryOptions,
  })
}

//-------------------------------------------------
export const getCredits = async () => {
  let { data } = await auth({
    method: 'get',
    url: `/auth/user/credits`,
  })
  return data
}

export const useCredits = ({ queryOptions } = {}) => {
  return useQuery(['credits'], getCredits, {
    cacheTime: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 60,
    ...queryOptions,
  })
}
