import { pubicClient } from 'api'
import React from 'react'
import { useQuery } from 'react-query'

export const getCountries = async () => {
  let { data } = await pubicClient({
    method: 'get',
    url: `/guest/user/countries`,
  })
  return data
}

export const useCountries = ({ queryOptions } = {}) => {
  return useQuery(['countries'], getCountries, {
    cacheTime: Infinity,
    staleTime: Infinity,
    ...queryOptions,
  })
}

export const useCountry = (id) => {
  const { data } = useCountries({
    queryOptions: {
      select: React.useCallback(
        (data) => {
          return data.find((country) => country.id === id)
        },
        [id]
      ),
    },
  })
  return data
}
