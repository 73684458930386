import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // eslint-disable-next-line no-undef
    debug: process.env.NODE_ENV === 'production' ? false : true,
    lng: 'en',
    fallbackLng: 'en',
    whitelist: ['en', 'gr'],
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
    },
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'sup'],
      useSuspense: true,
    },
  })

export default i18n
