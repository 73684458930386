import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Toolbar, LinearProgress, Typography } from '@material-ui/core'

import clsx from 'clsx'

import ReaderDrawer from './drawer-container.component'
import ReaderAppbar from './reader-appbar.component'
import DrawerContent from './drawer-content.component'
import ReaderFrame from './reader-frame.component'
import { useReaderContext } from './context/reader.context'
import { useEpub3State } from './use-epub3-state.hook'

const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menuButton: {},
  hide: {
    display: 'none',
  },
  main: {
    width: '100%',
  },
  mainShift: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  header: {
    fontSize: 30,
    fontWeight: 300,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iframe: {
    width: 1060,
    height: `calc(100vh - ${62}px)`,
  },
  iframeShift: {},
}))

export const Epub3Reader = () => {
  const classes = useStyles()
  const {
    iRef,
    epub3: { url },
    info: { availableActivities, currentActivity },
    drawer: { open },
  } = useReaderContext()

  const {
    ui: { upLg, SHOW_ANSWERS_BUTTON, hasNext, hasPrev },
    actions: {
      handleLoad,
      handleToggleDrawer,
      handleNext,
      handlePrev,
      handleClickExercise,
      showAnswers,
      showNextAnswer,
      hideLastAnswer,
      showStudentAnswers,
    },
    hasLoaded,
    calculatedMargin,
  } = useEpub3State()

  return (
    <div className={classes.root}>
      <ReaderAppbar
        onOpen={handleToggleDrawer}
        onNext={handleNext}
        onPrev={handlePrev}
        hasNext={hasNext}
        hasPrev={hasPrev}
        onShowAnswers={showAnswers}
        onShowNextAnswer={showNextAnswer}
        onHideLastAnswer={hideLastAnswer}
        enableShowAnswers={SHOW_ANSWERS_BUTTON}
      />
      {availableActivities?.length ? (
        <>
          <ReaderDrawer>
            <DrawerContent onExerciseClick={handleClickExercise} />
          </ReaderDrawer>
        </>
      ) : null}

      <main
        className={clsx(classes.main, {
          [classes.mainShift]: open,
        })}
      >
        <Toolbar />
        {!hasLoaded && availableActivities?.length > 0 ? (
          <LinearProgress style={{ width: '1920px' }} />
        ) : null}
        {availableActivities?.length === 0 ? (
          <Typography
            variant="h1"
            color="primary"
            style={{ textAlign: 'center', padding: '150px 0' }}
          >
            No activities
          </Typography>
        ) : null}
        {currentActivity ? (
          <ReaderFrame
            variant="epub3"
            ref={iRef}
            loaded={hasLoaded}
            frameSrc={url}
            frameMargin={calculatedMargin}
            onLoad={handleLoad}
          />
        ) : null}
      </main>
    </div>
  )
}
