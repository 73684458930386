import React, { useCallback, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import { groupBy } from 'lodash'
import { useMediaQuery } from '@material-ui/core'

import { useCreateAttempt } from 'api/Package/Attempts/queries'
import useNotification from 'hooks/useNotification'
import parseError from 'helpers/errorHelpers'
import useWindowSize from 'hooks/useWindowSize'
import { userRoleSelector } from 'store/auth/selectors'
import { useSelector } from 'react-redux'
import { useReaderContext } from './context/reader.context'

const drawerWidth = 300

function bindEvent(element, eventName, eventHandler) {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false)
  } else if (element.attachEvent) {
    element.attachEvent('on' + eventName, eventHandler)
  }
}
function unBindEvent(element, eventName, eventHandler) {
  if (element.removeEventListener) {
    element.removeEventListener(eventName, eventHandler)
  } else if (element.detachEvent) {
    element.detachEvent('on' + eventName, eventHandler)
  }
}

// roles that dont post attempts and get show answers button shown immediately
const ROLES_MAIN = [
  'School Master',
  'School Manager',
  'Individual Teacher',
  'Admin',
  'Teacher',
  'Editor',
]

export const useDigipubState = () => {
  const {
    iRef,
    digipub: { setToc, targetActivities },
    readerMode: { IS_ASSIGNMENT, IS_MISSION, IS_PREVIEW, IS_READ },
    info: { currentActivity, availableActivities, currentIndex },
    drawer: { open },
    actions: { setCurrentActivity, setCurrentIndex, setAvailableActivities },
  } = useReaderContext()
  //   const theme = useTheme()
  const theme = useTheme()

  let userRole = useSelector(userRoleSelector)

  const currentSubSchool = useSelector((state) => state.auth.currentSubSchool)
  const SCHOOL_TYPE_2 = currentSubSchool?.type_id === 2

  const canPostAttemptRef = React.useRef(true)

  const currentActivityRef = React.useRef()

  useEffect(() => {
    currentActivityRef.current = currentActivity
  }, [currentActivity])

  const { addNotification } = useNotification()

  const createAttempt = useCreateAttempt({
    queryOptions: {
      onSuccess: async (data) => {
        if (data.elecs) {
          addNotification({
            type: 'success',
            message: `You have earned ${data.elecs} ELECs`,
          })
        }
      },
      onError: (error) => {
        addNotification({
          type: 'error',
          message: parseError(error, `Error creating Attempt`),
        })
      },
    },
  })

  const upLg = useMediaQuery(theme.breakpoints.up('xl'))

  const [hasLoaded, setHasLoaded] = React.useState(false)

  const initContents = useCallback(
    (toc) => {
      let arrayAll = toc.reduce((acc, item) => {
        return [...acc, ...Object.values(item)[0]]
      }, [])
      if (IS_READ) {
        setAvailableActivities(arrayAll)
        if (targetActivities.length) {
          let index = arrayAll.findIndex(
            (avail) => avail.digi_activity_id == targetActivities[0]
          )
          setCurrentIndex(index)
          setCurrentActivity(arrayAll[index])
        } else {
          setCurrentActivity(arrayAll[0])
          setCurrentIndex(0)
        }
        setToc(groupBy(arrayAll, 'digi_module_name'))
      } else if (IS_MISSION || IS_PREVIEW) {
        let missionActivities = arrayAll.filter((activity) =>
          targetActivities.includes(parseInt(activity.digi_activity_id))
        )
        setAvailableActivities(missionActivities)
        setCurrentActivity(missionActivities[0])
        setCurrentIndex(0)
        setToc(groupBy(missionActivities, 'digi_module_name'))
      } else if (IS_ASSIGNMENT) {
        let assignmentActivity = arrayAll.find(
          (activity) => activity.digi_activity_id == targetActivities[0]
        )
        setAvailableActivities([{ ...assignmentActivity }])
        setCurrentActivity(assignmentActivity)
        setCurrentIndex(0)
        setToc(groupBy([assignmentActivity], 'digi_module_name'))
      }
    },
    [
      IS_ASSIGNMENT,
      IS_MISSION,
      IS_PREVIEW,
      IS_READ,
      setAvailableActivities,
      setCurrentActivity,
      setCurrentIndex,
      setToc,
      targetActivities,
    ]
  )

  const handleCheckScore = useCallback(
    (score) => {
      createAttempt.mutate({
        score,
        activity_id: currentActivityRef.current?.digi_activity_id,
      })
    },
    [createAttempt]
  )

  const handleMessage = useCallback(
    (obj) => {
      // update: fixed
      // console.log('handleMessage', currentActivity) // tobe fixed,without ref,when listening to an event from iframe,current activity is from previous render
      let message
      if (typeof obj.data === 'string' && obj.data !== 'recaptcha-setup') {
        message = JSON.parse(obj.data)
        if (message.action === 'setToc' && !availableActivities.length) {
          initContents(message.toc)
        }
        if (message.action === 'setCurrentActivity') {
          // no need to use this event, because client state handle current activity
        }
        if (message.action === 'setScore') {
          if (ROLES_MAIN.includes(userRole)) return
          handleCheckScore(message.score)
        }
      }
    },
    [availableActivities, handleCheckScore, initContents, userRole]
  )

  const { width: windowWidth } = useWindowSize()

  let calculatedMargin = React.useMemo(() => {
    if ((upLg ? 1920 : windowWidth) - (open ? drawerWidth : 0) > 1060) {
      return `${
        ((upLg ? 1920 : windowWidth) - (open ? drawerWidth : 0) - 1060) / 2
      }px`
    } else return '0px'
  }, [upLg, windowWidth, open])

  const handleClickExercise = useCallback(
    (activity) => {
      if (!iRef?.current || !currentActivity) return
      if (activity.digi_activity_id === currentActivity.digi_activity_id) return
      let payload = JSON.stringify({
        action: 'fireEvent',
        eventType: 'read',
        activities: [activity.digi_activity_id],
      })
      setCurrentActivity(activity)

      iRef.current.contentWindow.postMessage(payload, '*')
      let index = availableActivities.findIndex(
        (avail) => avail.digi_activity_id === activity.digi_activity_id
      )
      setCurrentIndex(index)
      resetVariables()
    },
    [
      availableActivities,
      currentActivity,
      iRef,
      setCurrentActivity,
      setCurrentIndex,
    ]
  )

  const handleNext = useCallback(() => {
    if (!iRef?.current) return
    if (currentIndex < availableActivities?.length) {
      if (availableActivities[currentIndex + 1]) {
        let payload = JSON.stringify({
          action: 'fireEvent',
          eventType: 'read',
          activities: [availableActivities[currentIndex + 1].digi_activity_id],
        })
        iRef.current.contentWindow.postMessage(payload, '*')
        setCurrentActivity(availableActivities[currentIndex + 1])
      }
      setCurrentIndex(currentIndex + 1)
    }
    resetVariables()
  }, [
    availableActivities,
    currentIndex,
    iRef,
    setCurrentActivity,
    setCurrentIndex,
  ])

  const handlePrev = useCallback(() => {
    if (!iRef?.current) return
    if (currentIndex !== 0) {
      if (availableActivities[currentIndex - 1]) {
        setCurrentActivity(availableActivities[currentIndex - 1])
        let payload = JSON.stringify({
          action: 'fireEvent',
          eventType: 'read',
          activities: [availableActivities[currentIndex - 1].digi_activity_id],
        })
        iRef.current.contentWindow.postMessage(payload, '*')
      }
      setCurrentIndex(currentIndex - 1)
    }
    resetVariables()
  }, [
    availableActivities,
    currentIndex,
    iRef,
    setCurrentActivity,
    setCurrentIndex,
  ])

  const resetVariables = () => {
    canPostAttemptRef.current = true
  }

  React.useEffect(() => {
    bindEvent(window, 'message', handleMessage)
    return () => unBindEvent(window, 'message', handleMessage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoad = useCallback(() => {
    if (!iRef?.current) return
    setHasLoaded(true)
    let payload = {
      action: 'fireEvent',
      eventType: 'read',
    }
    if (targetActivities?.length)
      payload.activities = targetActivities.map((val) => Number(val))
    let message = JSON.stringify(payload)
    iRef.current.contentWindow.postMessage(message, '*')
  }, [iRef, targetActivities])

  const hasNext = React.useMemo(
    () =>
      availableActivities?.length - 1 !== currentIndex &&
      availableActivities?.length > 0,
    [availableActivities?.length, currentIndex]
  )

  const hasPrev = React.useMemo(
    () => currentIndex !== 0 && !!availableActivities?.length,
    [availableActivities?.length, currentIndex]
  )

  return {
    bindEvent,
    unBindEvent,
    iRef,
    ui: {
      upLg,
      open,
      hasNext,
      hasPrev,
    },
    actions: {
      handleLoad,
      handleNext,
      handlePrev,
      handleClickExercise,
    },
    calculatedMargin,
    hasLoaded,
  }
}
