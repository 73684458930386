import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import { IconButton } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { Close } from '@material-ui/icons'

import useSetState from 'hooks/useSetState'
import useNotification from 'hooks/useNotification'

Notification.propTypes = {}

const DISPLAY_TIME = 4000

function FilledAlert(props) {
  return <Alert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(5),
    '& > div': {
      minWidth: 300,
    },
  },
}))

export default function Notification() {
  const classes = useStyles()
  const { notifications, setNotifications } = useNotification()

  const [state, setState] = useSetState({
    open: false,
    vertical: 'bottom',
    horizontal: 'left',
    message: '',
    type: '',
    key: '',
  })

  const { vertical, horizontal, open, message, type, key } = state

  const handleClose = () => {
    setState({ open: false })
  }

  React.useEffect(() => {
    if (notifications.length && !key) {
      // Set a new snack when we don't have an active one
      setState({ message: notifications[0].message ?? 'Default message' })
      setState({ type: notifications[0].type ?? 'success' })
      setState({ vertical: notifications[0].vertical ?? 'bottom' })
      setState({ horizontal: notifications[0].horizontal ?? 'left' })
      setState({ key: notifications[0].key })

      setNotifications((prev) => prev.slice(1))
      setState({ open: true })
    } else if (notifications.length && key && open) {
      // Close an active snack when a new one is added
      setState({ open: false })
    }
  }, [notifications, open, setState, setNotifications, key])

  const handleExited = () => {
    setState({ key: '' })
  }

  return (
    <>
      <Snackbar
        className={classes.root}
        key={key}
        anchorOrigin={{
          vertical,
          horizontal,
        }}
        open={open}
        autoHideDuration={DISPLAY_TIME}
        onClose={handleClose}
        onExited={handleExited}
        // TransitionProps={{
        //   onClose: handleClose,
        //   onExited: handleExited,
        //   timeout: DISPLAY_TIME,
        // }}
        // TransitionComponent={state.Transition}
        action={
          <>
            <IconButton color="inherit" onClick={handleClose}>
              <Close />
            </IconButton>
          </>
        }
      >
        <FilledAlert onClose={handleClose} severity={type}>
          {message}
        </FilledAlert>
      </Snackbar>
    </>
  )
}
