import { useMutation, useQuery } from 'react-query'

import auth from 'api'
import { getTeacherClassUsers } from 'api/IndividualTeacher/ClassStudents/queries'
import { getClassUsers } from 'api/School/ClassUsers/queries'
import { getUser, getUsers } from 'api/User/Users/queries'
import {
  addToCondition,
  generateFilter,
  parseFilters,
  parsePage,
} from 'helpers/api'
import { uniq } from 'lodash'

const CONTROLLER = `pkg/attempts`

const QueryKeys = {
  attemptActivities: `attemptActivities`,
}

const MutationKeys = {}

// ---------------------------------------------------------

export const getAttempts = async ({ queryKey }) => {
  const [
    ,
    subSchoolId,
    page,
    { filters = [], includeModels = [], isManagerial } = {},
    mode,
  ] = queryKey
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  condition = addToCondition(condition, subSchoolId, 'sub_school_id')
  const { data: attempts } = await auth({
    method: 'get',
    url: `auth/${CONTROLLER}${mode === 'normal' ? '' : '/single'}${condition}`,
  })
  let attemptsWithData

  let usersData
  if (includeModels?.includes('Users') && attempts?.items.length) {
    if (isManagerial) {
      const userIds = attempts?.items.map((attempt) => attempt.user_id)
      const uniqueIds = uniq(userIds)
      if (uniqueIds.length) {
        const userFilter = generateFilter([], uniqueIds, 'id')
        usersData = await getUsers({
          queryKey: [null, null, { filters: userFilter }],
        })
      }
    } else {
      const currentUserId = attempts?.items[0].user_id
      const { user } = await getUser({
        queryKey: [null, currentUserId],
      })
      usersData = { items: [user] } // to fernw sth morfh pou xrhsimopoieui h reduce pio katw
    }
  }
  let classUserData
  if (includeModels?.includes('ClassUsers')) {
    const userIds = attempts?.items.map((attempt) => attempt.user_id)
    const uniqueIds = uniq(userIds)
    if (uniqueIds.length) {
      const classUsersFilter = generateFilter([], uniqueIds, 'user_id')
      classUserData = await getClassUsers({
        queryKey: [null, null, null, { filters: classUsersFilter }],
      })
    }
  }
  attemptsWithData = {
    ...attempts,
    items: attempts.items.reduce((acc, attempt) => {
      let tempAttempt = attempt
      if (includeModels?.includes('Users') && usersData?.items.length) {
        let idx = usersData.items.findIndex((usr) => usr.id === attempt.user_id)
        if (idx !== -1) tempAttempt.User = usersData.items[idx]
      }
      if (
        includeModels?.includes('ClassUsers') &&
        classUserData.items?.length
      ) {
        let userClasses = classUserData.items.filter(
          (classUser) => classUser.user_id === attempt.user_id
        )
        if (userClasses?.length) tempAttempt.Classes = userClasses
      }
      return [...acc, tempAttempt]
    }, []),
  }

  return attemptsWithData
}

export const useAttempts = (
  subSchoolId,
  page,
  { queryFilters = {}, queryOptions = {} } = {},
  mode = 'normal' // "normal" or "single"
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'attempts',
      subSchoolId,
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
      mode,
    ],
    getAttempts,
    {
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 60,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}
// -----------------------------------
export const createAttempt = async (payload = {}) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/${CONTROLLER}`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useCreateAttempt = ({ queryOptions } = {}) => {
  return useMutation(createAttempt, {
    ...queryOptions,
  })
}
// -----------------------------------
export const updateAttempt = async ({ id, payload }) => {
  const { data } = await auth({
    method: 'put',
    url: `auth/${CONTROLLER}/${id}`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useUpdateAttempt = ({ queryOptions } = {}) => {
  return useMutation(updateAttempt, {
    ...queryOptions,
  })
}
// -----------------------------------
export const deleteAttempt = async (id) => {
  await auth({
    method: 'delete',
    url: `auth/${CONTROLLER}/${id}`,
  })
}
export const useDeleteAttempt = ({ queryOptions } = {}) => {
  return useMutation(deleteAttempt, {
    ...queryOptions,
  })
}
// -----------------------------------
export const getAttemptsModules = async ({ queryKey }) => {
  const [, subSchoolId, page, { filters = [] } = {}] = queryKey
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  condition = addToCondition(condition, subSchoolId, 'sub_school_id')
  const { data: attemptsModules } = await auth({
    method: 'get',
    url: `auth/${CONTROLLER}/modules${condition}`,
  })

  return attemptsModules
}

export const useAttemptsModules = (
  subSchoolId,
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'attemptsModules',
      subSchoolId,
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    getAttemptsModules,
    {
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 60,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}

// ---------------------------------------------------------

export const getAttemptsActivitiesNEW = async ({
  subSchoolId = null,
  moduleId = null,
  page = null,
  filters = [],
} = {}) => {
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  condition = addToCondition(condition, subSchoolId, 'sub_school_id')
  condition = addToCondition(condition, moduleId, 'module_id')

  const { data } = await auth({
    method: 'get',
    url: `auth/${CONTROLLER}/activities${condition}`,
  })

  return data
}

export const useAttemptsActivitiesMutationNEW = ({ queryOptions } = {}) => {
  return useMutation(getAttemptsActivitiesNEW, {
    ...queryOptions,
  })
}

export const useAttemptsActivitiesNEW = (
  { subSchoolId = null, page = null, ...rest } = {},
  options = {}
) => {
  return useQuery(
    [QueryKeys.attemptActivities, page, subSchoolId],
    () => getAttemptsActivitiesNEW({ subSchoolId, page, ...rest }),
    {
      ...options,
    }
  )
}

// ---------------------------------------------------------

export const getAttemptsActivities = async ({ queryKey }) => {
  const [, subSchoolId, moduleId, page, { filters = [] } = {}] = queryKey
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  condition = addToCondition(condition, subSchoolId, 'sub_school_id')
  condition = addToCondition(condition, moduleId, 'module_id')
  const { data: attemptsModules } = await auth({
    method: 'get',
    url: `auth/${CONTROLLER}/activities${condition}`,
  })

  return attemptsModules
}

export const useAttemptsActivities = (
  subSchoolId,
  moduleId,
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  return useQuery(
    [
      'attemptsActivities',
      subSchoolId,
      moduleId,
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    getAttemptsActivities,
    {
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 60,
      ...queryOptions,
    }
  )
}
// -----------------------------------
export const searchAttempts = async ({
  page,
  filters,
  includeModels,
  isManagerial,
}) => {
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  const { data: attempts } = await auth({
    method: 'get',
    url: `auth/${CONTROLLER}${condition}`,
  })
  let attemptsWithData

  let usersData
  if (includeModels?.includes('Users') && attempts?.items.length) {
    if (isManagerial) {
      const userIds = attempts?.items.map((attempt) => attempt.user_id)
      const uniqueIds = uniq(userIds)
      if (uniqueIds.length) {
        const userFilter = generateFilter([], uniqueIds, 'id')
        usersData = await getUsers({
          queryKey: [null, null, { filters: userFilter }],
        })
      }
    } else {
      const currentUserId = attempts?.items[0].user_id
      const { user } = await getUser({
        queryKey: [null, currentUserId],
      })
      usersData = { items: [user] } // to fernw sth morfh pou xrhsimopoieui h reduce pio katw in case of student
    }
  }
  let classUserData
  if (includeModels?.includes('ClassUsers') && attempts?.items?.length) {
    const userIds = attempts?.items.map((attempt) => attempt.user_id)
    const uniqueIds = uniq(userIds)
    if (uniqueIds.length) {
      const classUsersFilter = generateFilter([], uniqueIds, 'user_id')
      classUserData = await getClassUsers({
        queryKey: [null, null, null, { filters: classUsersFilter }],
      })
    }
  }
  if (
    includeModels?.includes('TeacherClassStudents') &&
    attempts?.items?.length
  ) {
    const userIds = attempts?.items.map((attempt) => attempt.user_id)
    const uniqueIds = uniq(userIds)
    if (uniqueIds.length) {
      const classUsersFilter = generateFilter([], uniqueIds, 'user_id')
      classUserData = await getTeacherClassUsers({
        queryKey: [null, null, null, { filters: classUsersFilter }],
      })
    }
  }

  attemptsWithData = {
    ...attempts,
    items: attempts.items.reduce((acc, attempt) => {
      let tempAttempt = attempt
      if (includeModels?.includes('Users') && usersData?.items.length) {
        let idx = usersData.items.findIndex((usr) => usr.id === attempt.user_id)
        if (idx !== -1) tempAttempt.User = usersData.items[idx]
      }
      if (
        includeModels?.includes('ClassUsers') &&
        classUserData.items?.length
      ) {
        let userClasses = classUserData.items.filter(
          (classUser) => classUser.user_id === attempt.user_id
        )
        if (userClasses?.length) tempAttempt.Classes = userClasses
      }
      if (
        includeModels?.includes('TeacherClassStudents') &&
        classUserData.items?.length
      ) {
        let userClasses = classUserData.items.filter(
          (classUser) => classUser.user_id === attempt.user_id
        )
        if (userClasses?.length) tempAttempt.TeacherClasses = userClasses
      }
      return [...acc, tempAttempt]
    }, []),
  }

  return attemptsWithData
  // return attempts
}

export const useSearchAttempts = ({ queryOptions = {} } = {}) => {
  return useMutation(searchAttempts, {
    ...queryOptions,
  })
}

// -----------------------------------

export const getClassUsersAttempts = async ({ queryKey }) => {
  const [
    ,
    activityId,
    created = null,
    deadline = null,
    // type='latest',
    { filters = [], includeModels = [], users = [] } = {},
    mode,
  ] = queryKey
  let condition = ''
  condition = parseFilters(filters, condition)
  condition = addToCondition(condition, activityId, 'activity_id')

  const promiseArray = users.map((usr) => {
    let userCondition = addToCondition(condition, usr, 'user_id')
    return async () =>
      auth({
        method: 'get',
        url: `auth/${CONTROLLER}${
          mode === 'normal' ? '' : '/single'
        }?per_page=1${userCondition}`,
      })
  })

  let results = []
  for (const promiseItem of promiseArray) {
    let { data } = await promiseItem()
    if (data?.items.length) {
      results.push(...data?.items)
    }
  }
  let resultsLastAttempt = []

  if (includeModels?.includes('LastAttempt')) {
    let idx = filters.findIndex((item) => item.by === 'sub_school_id')
    let lastAttemptFilters
    if (idx !== -1) {
      lastAttemptFilters = [
        filters[idx],
        { by: 'sort_by', value: 'created.desc' },
        { by: 'created[lte]', value: deadline },
        { by: 'created[gte]', value: created },
      ]
    }

    let conditionLastAttempt = ''
    conditionLastAttempt = parseFilters(
      lastAttemptFilters,
      conditionLastAttempt
    )
    conditionLastAttempt = addToCondition(
      conditionLastAttempt,
      activityId,
      'activity_id'
    )

    const promiseArray = users.map((usr) => {
      let userCondition = addToCondition(conditionLastAttempt, usr, 'user_id')
      return async () =>
        auth({
          method: 'get',
          url: `auth/${CONTROLLER}${
            mode === 'normal' ? '' : '/single'
          }?per_page=1${userCondition}`,
        })
    })

    for (const promiseItem of promiseArray) {
      let { data } = await promiseItem()
      if (data?.items.length) {
        resultsLastAttempt.push(...data?.items)
      }
    }
  }

  let attemptsWithData

  let usersData
  if (includeModels?.includes('Users') && users?.length) {
    // if (isManagerial) {
    const userIds = users
    const uniqueIds = uniq(userIds)
    if (uniqueIds.length) {
      const userFilter = generateFilter([], uniqueIds, 'id')
      usersData = await getUsers({
        queryKey: [null, null, { filters: userFilter }],
      })
    }
    // }
  }

  attemptsWithData = usersData.items.reduce((acc, usr) => {
    if (usr) {
      let tempUsr = usr
      if (results.length) {
        let idx = results.findIndex((res) => res.user_id === tempUsr.id)
        if (idx !== -1) {
          tempUsr.FirstAttempt = results[idx]
        } else {
          tempUsr.FirstAttempt = null
        }
      }
      if (
        includeModels?.includes('LastAttempt') &&
        resultsLastAttempt?.length
      ) {
        let idx = resultsLastAttempt.findIndex(
          (attmpt) => attmpt.user_id === tempUsr.id
        )
        if (idx !== -1) {
          tempUsr.LastAttempt = resultsLastAttempt[idx]
        } else {
          tempUsr.FirstAttempt = null
        }
      }

      return [...acc, tempUsr]
    } else return acc
  }, [])

  return attemptsWithData
}

export const useClassUsersAttempts = (
  activityId,
  created,
  deadline,
  { queryFilters = {}, queryOptions = {} } = {},
  mode = 'normal' // "normal" or "single"
) => {
  const { data, ...rest } = useQuery(
    ['classAttempts', activityId, created, deadline, { ...queryFilters }, mode],
    getClassUsersAttempts,
    {
      ...queryOptions,
    }
  )

  return { data, ...rest }
}

// -----------------------------------
export const getAttempt = async ({ queryKey }) => {
  const [, id] = queryKey
  const { data } = await auth({
    method: 'get',
    url: `auth/${CONTROLLER}/${id}`,
  })
  return data
}
export const useAttempt = (id, { queryOptions } = {}) => {
  return useQuery(['attempt', id], getAttempt, {
    ...queryOptions,
  })
}

// ---------------------------------------------------------

const Attempts = {
  QueryKeys,
  MutationKeys,
  fns: {
    getAttemptsActivitiesNEW,
  },
  hooks: {
    useAttemptsActivitiesNEW,
    useAttemptsActivitiesMutationNEW,
  },
}
export default Attempts

// ---------------------------------------------------------

export const getCompletedAttempts = async ({ queryKey }) => {
  const [
    ,
    subSchoolId,
    page,
    { filters = [], users = [], activities = [] } = {},
  ] = queryKey
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  condition = addToCondition(condition, subSchoolId, 'sub_school_id')

  condition = addToCondition(condition, users, 'user_id')
  condition = addToCondition(condition, activities, 'activity_id')

  const { data: completedAttempts } = await auth({
    method: 'get',
    url: `auth/${CONTROLLER}/group/completed-activities${condition}`,
  })
  return completedAttempts
}

export const useCompletedAttempts = (
  subSchoolId,
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'completed-attempts',
      subSchoolId,
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    getCompletedAttempts,
    {
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 60,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}
