function parseError(error, def = 'Default Error') {
  if (typeof error === 'string') return error
  const STATUS = error?.response?.status
  const DATA = error?.response?.data
  switch (STATUS) {
    case 401:
      switch (typeof DATA) {
        case 'string':
          return DATA
        case 'object':
          return DATA?.message ?? def
        default:
          return def
      }
    case 400:
      switch (typeof DATA) {
        case 'string':
          return DATA
        case 'object':
          return DATA?.message ?? def
        default:
          return def
      }
    case 404:
      switch (typeof DATA) {
        case 'string':
          return DATA
        case 'object':
          return DATA?.message ?? def
        default:
          return def
      }
    case 403:
      switch (typeof DATA) {
        case 'string':
          return DATA
        case 'object':
          return DATA?.message ?? def
        default:
          return def
      }
    default:
      return def
  }
}

export default parseError
