import React, {
  createContext,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useContext } from 'react'

const PREFERRED_LANGUAGE_KEY = 'digilanguage'

const acceptedLanguages = ['en', 'gr']

const getPreferredLanguage = () => {
  const storedLanguage = localStorage.getItem(PREFERRED_LANGUAGE_KEY)
  if (storedLanguage && acceptedLanguages.includes(storedLanguage)) {
    return storedLanguage
  }
}

const useLanguageContextState = () => {
  const [language, setLanguage] = useState('en')

  const { i18n } = useTranslation()

  useLayoutEffect(() => {
    const storedLanguage = getPreferredLanguage() ?? i18n.language
    changeLanguage(storedLanguage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeLanguage = useCallback(
    (language) => {
      localStorage.setItem(PREFERRED_LANGUAGE_KEY, language)
      setLanguage(language)
      i18n.changeLanguage(language)
    },
    [i18n]
  )

  return {
    language,
    actions: {
      changeLanguage,
    },
  }
}

const LanguageContext = createContext()

LanguageContext.displayName = 'LanguageContext'

const LanguageContextProvider = ({ children }) => {
  const state = useLanguageContextState()
  return (
    <LanguageContext.Provider
      value={{
        ...state,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

LanguageContextProvider.propTypes = {
  children: PropTypes.node,
}

const useLanguageContext = () => useContext(LanguageContext)

export { LanguageContext, LanguageContextProvider, useLanguageContext }
