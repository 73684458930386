import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Grid,
  SvgIcon,
  MenuItem,
  Menu,
  Grow,
} from '@material-ui/core'

import {
  Menu as MenuIcon,
  MenuOpen,
  ArrowForwardIos,
  ArrowBackIos,
} from '@material-ui/icons'
import clsx from 'clsx'
import { ReactComponent as TipsAndUpdates } from 'assets/svg/tips_and_updates.svg'
import { ReactComponent as PreviousAnswer } from 'assets/svg/previous_answer.svg'
import { ReactComponent as NextAnswer } from 'assets/svg/next_answer.svg'
import { useReaderContext } from './context/reader.context'
import { useMemo } from 'react'

import { useSelector } from 'react-redux'
import { userRoleSelector } from 'store/auth/selectors'
import roles from 'constants/roles'

const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.background.paper,
    color: 'black',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menuButton: {},
  arrowButton: {
    margin: theme.spacing(0, 1),
  },
  hide: {
    display: 'none',
  },
  title: {},
  header: {
    fontSize: 30,
    fontWeight: 300,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    color: '#3d5265',
  },
}))

function ReaderAppbar({
  hasNext,
  hasPrev,
  onNext,
  onPrev,
  onShowAnswers,
  onShowNextAnswer,
  onHideLastAnswer,
  enableShowAnswers,
}) {
  const classes = useStyles()

  const [moreAnchorEl, setMoreAnchorEl] = React.useState(null)
  const isMenuOpen = Boolean(moreAnchorEl)

  const userRole = useSelector(userRoleSelector)
  const IS_STUDENT = userRole === roles.STUDENT //|| userRole === roles.MEMBER

  const {
    info: { title, isTabPages, availableActivities },
    readerMode: { IS_EPUB, IS_DIGIPUB, IS_DIWB },
    drawer: { open, handleToggleDrawer },
  } = useReaderContext()

  const hasDrawer = useMemo(
    () => availableActivities.length > 0,
    [availableActivities]
  )

  const hideControls = useMemo(
    () => (IS_EPUB || IS_DIWB ? false : IS_DIGIPUB ? false : true),
    [IS_EPUB, IS_DIWB, IS_DIGIPUB]
  )

  const handleMenuOpen = (event) => {
    setMoreAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMoreAnchorEl(null)
  }

  const menuId = 'primary-search-account-menu'

  const renderMenu = (
    <Menu
      anchorEl={moreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={onShowAnswers}>
        <IconButton aria-controls={menuId} className={classes.iconWrapper}>
          <SvgIcon component={TipsAndUpdates} fontSize="inherit" />
        </IconButton>
        <div>All answers</div>
      </MenuItem>
      <MenuItem onClick={onShowNextAnswer}>
        <IconButton aria-controls={menuId} className={classes.iconWrapper}>
          <SvgIcon component={NextAnswer} fontSize="inherit" />
        </IconButton>
        <div className="classes.text">Next answer</div>
      </MenuItem>
      <MenuItem onClick={onHideLastAnswer}>
        <IconButton aria-controls={menuId} className={classes.iconWrapper}>
          <SvgIcon component={PreviousAnswer} fontSize="inherit" />
        </IconButton>
        <div>Previous answers</div>
      </MenuItem>
    </Menu>
  )

  return (
    <>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open && hasDrawer,
        })}
      >
        <Toolbar disableGutters={false} className={classes.toolbar}>
          {hasDrawer ? (
            <IconButton
              onClick={handleToggleDrawer}
              className={classes.menuButton}
              color="primary"
            >
              {open ? <MenuOpen /> : <MenuIcon />}
            </IconButton>
          ) : null}
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={5}
          >
            {hideControls || isTabPages ? null : (
              <Grid item>
                <IconButton
                  disabled={!hasPrev}
                  onClick={onPrev}
                  color="primary"
                >
                  <ArrowBackIos />
                </IconButton>
              </Grid>
            )}

            <Grid item>
              <Typography
                component="div"
                variant="h6"
                className={classes.title}
              >
                {title}
              </Typography>
            </Grid>
            {hideControls || isTabPages ? null : (
              <Grid item>
                <IconButton
                  disabled={!hasNext}
                  onClick={onNext}
                  color="primary"
                >
                  <ArrowForwardIos />
                </IconButton>
              </Grid>
            )}
          </Grid>
          {IS_STUDENT ? (
            <Grow in={enableShowAnswers}>
              <IconButton
                onClick={onShowAnswers}
                aria-controls={menuId}
                className={classes.iconWrapper}
                color="primary"
              >
                <SvgIcon
                  color="primary"
                  component={TipsAndUpdates}
                  fontSize="inherit"
                />
              </IconButton>
            </Grow>
          ) : IS_DIWB ? (
            <Grow in={enableShowAnswers}>
              <IconButton
                aria-controls={menuId}
                onClick={handleMenuOpen}
                className={classes.iconWrapper}
                color="primary"
              >
                <SvgIcon
                  color="primary"
                  component={TipsAndUpdates}
                  fontSize="inherit"
                />
              </IconButton>
            </Grow>
          ) : (
            <Grow in={enableShowAnswers}>
              <IconButton
                aria-controls={menuId}
                onClick={onShowAnswers}
                className={classes.iconWrapper}
                color="primary"
              >
                <SvgIcon
                  color="primary"
                  component={TipsAndUpdates}
                  fontSize="inherit"
                />
              </IconButton>
            </Grow>
          )}
          {renderMenu}
        </Toolbar>
      </AppBar>
    </>
  )
}

ReaderAppbar.propTypes = {
  hasDrawer: PropTypes.bool,
  hideControls: PropTypes.bool,
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  hasNext: PropTypes.bool,
  hasPrev: PropTypes.bool,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onShowAnswers: PropTypes.func,
  onShowNextAnswer: PropTypes.func,
  onHideLastAnswer: PropTypes.func,
  enableShowAnswers: PropTypes.bool,
  currentTab: PropTypes.number,
}

export default ReaderAppbar
