import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import {
  ButtonBase,
  Button as MaterialButton,
  Link,
  IconButton,
} from '@material-ui/core'
import {
  primary,
  secondary,
  green,
  blue,
  red,
  purple,
  cyan,
  text,
  grey,
  warning,
  white,
} from 'theme'

const useStyles = makeStyles((theme) => ({
  root: {},
  rounded: {
    ...theme.typography.mainbutton,
  },
  small: {
    ...theme.typography.mainbuttonsmall,
  },
}))

// eslint-disable-next-line react/display-name
const Button = React.forwardRef(
  (
    {
      children,
      to,
      className,
      muiClasses,
      color = 'text',
      isLink,
      rounded,
      small,
      isIcon,
      isImage,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles()

    const themes = {
      primary: primary,
      secondary: secondary,
      green: green,
      red: red,
      purple: purple,
      cyan,
      text,
      grey,
      blue,
      warning,
      white,
    }

    const btnClasses = clsx(
      rounded && !small && classes.rounded,
      small && classes.small,
      {
        [className]: className,
      }
    )

    if (isImage) {
      if (isLink) {
        return (
          <Link component={RouterLink} to={to}>
            <ButtonBase
              ref={ref}
              {...rest}
              classes={muiClasses}
              className={btnClasses}
            >
              {children}
            </ButtonBase>
          </Link>
        )
      } else
        return (
          <ButtonBase
            ref={ref}
            {...rest}
            classes={muiClasses}
            className={btnClasses}
          >
            {children}
          </ButtonBase>
        )
    } else
      return (
        <ThemeProvider theme={themes[color]}>
          {isLink ? (
            <Link component={RouterLink} to={to}>
              {isIcon ? (
                <IconButton
                  {...rest}
                  ref={ref}
                  color="primary"
                  classes={muiClasses}
                  className={btnClasses}
                  // className={clsx(
                  //   rounded && !small && classes.rounded,
                  //   small && classes.small
                  // )}
                >
                  {children}
                </IconButton>
              ) : (
                <MaterialButton
                  {...rest}
                  ref={ref}
                  color="primary"
                  classes={muiClasses}
                  className={btnClasses}
                  // className={clsx(
                  //   rounded && !small && classes.rounded,
                  //   small && classes.small
                  // )}
                >
                  {children}
                </MaterialButton>
              )}
            </Link>
          ) : isIcon ? (
            <IconButton
              {...rest}
              ref={ref}
              color="primary"
              classes={muiClasses}
              className={btnClasses}
            >
              {children}
            </IconButton>
          ) : (
            <MaterialButton
              {...rest}
              ref={ref}
              color="primary"
              classes={muiClasses}
              className={btnClasses}
              // className={clsx(
              //   rounded && !small && classes.rounded,
              //   small && classes.small
              // )}
            >
              {children}
            </MaterialButton>
          )}
        </ThemeProvider>
      )
  }
)

export default Button

Button.propTypes = {
  className: PropTypes.string,
  muiClasses: PropTypes.object,
  isImage: PropTypes.bool,
  isLink: PropTypes.bool,
  isIcon: PropTypes.bool,
  children: PropTypes.node,
  to: PropTypes.string,
  rounded: PropTypes.bool,
  small: PropTypes.bool,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'green',
    'red',
    'purple',
    'grey',
    'cyan',
    'text',
    'blue',
    'warning',
    'white',
  ]),
}
Button.defaultProps = {
  to: '/',
}
