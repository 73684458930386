import {
  SET_USER,
  SET_ACCESS_TOKEN,
  SET_CURRENT_SCHOOL_YEAR,
  SET_CURRENT_SUB_SCHOOL,
  SET_INDIVIDUAL_TEACHER,
} from './types'

const initialState = {
  user: {},
  currentSchoolYear: null,
  currentSubSchool: null,
  currentIndividualTeacher: null,
  accessToken: null,
  status: 'idle',
  error: false,
  countries: [],
}

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_USER:
      return { ...state, user: payload.value }
    case SET_CURRENT_SCHOOL_YEAR:
      return { ...state, currentSchoolYear: payload }
    case SET_CURRENT_SUB_SCHOOL:
      return { ...state, currentSubSchool: payload }
    case SET_ACCESS_TOKEN:
      return { ...state, accessToken: payload.value }
    case SET_INDIVIDUAL_TEACHER:
      return { ...state, currentIndividualTeacher: payload }
    default:
      return state
  }
}
