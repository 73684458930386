import { useQuery, useMutation } from 'react-query'
import { uniq } from 'lodash'

import auth from 'api'
import {
  parseFilters,
  parsePage,
  generateFilter,
  parseSearch,
} from 'helpers/api'
import { getLevel } from 'api/School/Levels/queries'
import { getUsers } from 'api/User/Users/queries'

import { parseDateToSeconds } from 'helpers/dateHelpers'

// ---------------------------------------------------------

export const searchTableLessons = async ({ queryKey }) => {
  const [, page, { filters = [], searchModels = {} } = {}] = queryKey

  let foundUsers
  let userIds = []

  let lessonsFilters = filters

  if (searchModels['Users']) {
    let userSearch = parseSearch('', searchModels, 'Users')
    foundUsers = await getUsers({
      queryKey: [
        null,
        null,
        {
          search: userSearch,
        },
      ],
    })
    userIds = foundUsers?.items.map((usr) => usr.id)

    lessonsFilters = generateFilter(filters, userIds, 'teacher_id')
  }

  let includeModelsLessons = []
  if (!searchModels['Users']) includeModelsLessons.push('Users')

  if (searchModels['Users'] && foundUsers.total_items === 0)
    return { items: [] }

  let lessonsSearch
  if (searchModels['Lessons']) {
    lessonsSearch = parseSearch('', searchModels, 'Lessons')
  }
  const foundLessons = await getLessons({
    queryKey: [
      null,
      page,
      {
        filters: lessonsFilters,
        search: lessonsSearch,
        includeModels: includeModelsLessons,
      },
    ],
  })

  let lessonsWithData = {
    ...foundLessons,
    items: foundLessons.items.reduce((acc, lssn) => {
      let lesson = lssn

      if (searchModels['Users'] && foundUsers?.items.length) {
        let idx = foundUsers.items.findIndex(
          (usr) => usr.id === lssn.teacher_id
        )
        if (idx !== -1) {
          lesson.User = foundUsers.items[idx]
        }
      }
      return [...acc, lesson]
    }, []),
  }
  return lessonsWithData

  // return foundLessons
}
export const useSearchTableLessons = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'searchLessons',
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    searchTableLessons,
    {
      refetchOnWindowFocus: false,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 10,
      ...queryOptions,
    }
  )

  // React.useEffect(() => {
  //   if (data?.total_pages > data?.current_page && !paginationIsDisabled) {
  //     queryClient.prefetchQuery(
  //       ['searchLessons', page + 2, { ...queryFilters }],
  //       searchTableLessons
  //     )
  //   }
  // }, [data, page, queryClient, queryFilters, paginationIsDisabled])

  return { data, ...rest }
}

//  --------------------------------------------------

export const getLessons = async ({ queryKey }) => {
  const [, page, { filters = [], includeModels = [], search } = {}] = queryKey
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  if (search) condition += `&${search}`
  const { data: lessons } = await auth({
    method: 'get',
    url: `auth/school/lessons${condition}`,
  })

  let usersData
  const userIDs = lessons?.items.map((lesson) => lesson.teacher_id)
  if (includeModels?.includes('Users')) {
    const uniqueIds = uniq(userIDs)
    if (uniqueIds.length) {
      const userFilter = generateFilter([], uniqueIds, 'id')
      usersData = await getUsers({
        queryKey: [null, null, { filters: userFilter }],
      })
    }
    // if (userIDs.length) {
    //   usersData = await getUsers({
    //     queryKey: [null, null, { users: userIDs }],
    //   })
    // }
  }
  let lessonsWithUserData

  lessonsWithUserData = {
    ...lessons,
    items: lessons.items.reduce((acc, lsn) => {
      let lesson = { ...lsn }
      if (includeModels?.includes('Users') && userIDs.length) {
        let idx = usersData.items.findIndex((usr) => usr.id === lsn.teacher_id)
        if (idx !== -1) {
          lesson.User = { ...usersData.items[idx] }
        }
      }
      return [...acc, lesson]
    }, []),
  }
  return lessonsWithUserData
}

export const useLessons = (page, { queryFilters = {}, queryOptions } = {}) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    ['lessons', paginationIsDisabled ? null : page + 1, { ...queryFilters }],
    getLessons,
    {
      keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 30,
      ...queryOptions,
    }
  )

  // React.useEffect(() => {
  //   if (data?.total_pages > data?.current_page) {
  //     queryClient.prefetchQuery(
  //       ['lessons', page + 2, { ...queryFilters }],
  //       getLessons,
  //       {
  //         keepPreviousData: paginationIsDisabled ? false : true,
  //         staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 60,
  //       }
  //     )
  //   }
  // }, [data, paginationIsDisabled, page, queryClient, queryFilters])

  return { data, ...rest }
}

// ---------------------------------------------------------
export const getLesson = async ({ queryKey }) => {
  const [, id, { includeModels = [] } = {}] = queryKey
  const { data: lesson } = await auth({
    method: 'get',
    url: `auth/school/lessons/${id}`,
  })
  let lessonWithClassData = { ...lesson }
  if (includeModels?.includes('Users')) {
    const teacherId = lesson?.teacher_id
    const teacher = await getUsers({
      queryKey: [null, null, { users: [teacherId] }],
    })
    lessonWithClassData.User = { ...teacher.items[0] }
  }
  if (includeModels?.includes('Levels')) {
    const levelId = lesson?.Class.level_id
    const level = await getLevel({
      queryKey: [null, levelId],
    })

    lessonWithClassData.Level = { ...level }
  } else return
  return lessonWithClassData
}
export const useLesson = (
  id,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  return useQuery(['lesson', id, { ...queryFilters }], getLesson, {
    ...queryOptions,
  })
}
// -----------------------------------
export const createLesson = async ({ payload }) => {
  let { starts, ...rest } = payload
  let date

  if (starts) {
    date = parseInt(parseDateToSeconds(payload.starts))
    rest = { ...rest, starts: date }
  }
  const { data } = await auth({
    method: 'post',
    url: `auth/school/lessons`,
    data: {
      lesson: {
        ...rest,
      },
    },
  })
  return data
}

export const useCreateLesson = ({ queryOptions } = {}) => {
  return useMutation(createLesson, {
    ...queryOptions,
  })
}

// -----------------------------------
export const createLessons = async ({ users, id: classId }) => {
  let promiseArray
  let res
  if (users?.length) {
    promiseArray = users.map((user) =>
      createLesson({
        payload: {
          class_id: classId,
          teacher_id: user.id,
          title: user.title,
        },
      })
    )
    res = await Promise.allSettled(promiseArray)
  }
  return res
}
export const useCreateLessons = ({ queryOptions } = {}) => {
  return useMutation(createLessons, {
    ...queryOptions,
  })
}

// -----------------------------------

export const updateLesson = async ({ id, payload }) => {
  const { starts, ...rest } = payload
  const { data } = await auth({
    method: 'put',
    url: `auth/school/lessons/${id}`,
    data: {
      lesson: {
        starts: parseInt(parseDateToSeconds(starts)),
        ...rest,
      },
    },
  })
  return data
}
export const useUpdateLesson = ({ queryOptions } = {}) => {
  return useMutation(updateLesson, {
    ...queryOptions,
  })
}

// -----------------------------------

export const deleteLesson = async (id) => {
  await auth({
    method: 'delete',
    url: `auth/school/lessons/${id}`,
  })
}
export const useDeleteLesson = ({ queryOptions } = {}) => {
  return useMutation(deleteLesson, {
    ...queryOptions,
  })
}
