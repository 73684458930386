import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import LogoInspire from 'assets/img/logoInspire.png'

General.propTypes = {}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  logo: {
    maxWidth: 310,
    width: '100%',
  },
}))

function General() {
  let classes = useStyles()
  return (
    <div className={classes.root}>
      <img src={LogoInspire} className={classes.logo} />
    </div>
  )
}

export default General
