import auth from 'api'
import { getPackages } from 'api/Package/Packages/queries'
import {
  generateFilter,
  parseFilters,
  parsePage,
  parseSearch,
} from 'helpers/api'
import { uniq } from 'lodash'
import { useMutation, useQuery } from 'react-query'

// -----------------------------------

export const searchMissions = async ({ queryKey }) => {
  const [, page, { filters = [], includeModels = [], searchModels = {} } = {}] =
    queryKey

  let foundPackages
  let packagesIds = []

  let missionsFilters = filters

  if (searchModels['Packages']) {
    let packagesSearch = parseSearch('', searchModels, 'Packages')
    foundPackages = await getPackages({
      queryKey: [
        null,
        null,
        {
          search: packagesSearch,
        },
      ],
    })
    packagesIds = foundPackages?.items.map((pckg) => pckg.id)

    missionsFilters = generateFilter(filters, packagesIds, 'package_id')
  }

  let includeModelsMissions = []
  if (!searchModels['Packages']) includeModelsMissions.push('Packages')

  if (searchModels['Packages'] && foundPackages.total_items === 0)
    return { items: [] }

  let tagSearch = ''
  if (searchModels['Missions']) {
    tagSearch = parseSearch('', searchModels, 'Missions')
  }

  const foundMissions = await getMissions({
    queryKey: [
      null,
      page,
      {
        search: tagSearch,
        filters: missionsFilters,
        includeModels: includeModelsMissions,
      },
    ],
  })

  let missionsWithData = foundMissions
  if (includeModels?.includes('Packages') && foundPackages?.items?.length) {
    missionsWithData.items = foundMissions.items.reduce((acc, mission) => {
      let current
      let idx = foundPackages.items.findIndex(
        (pckg) => pckg.id === mission.package_id
      )
      if (idx !== -1)
        return [
          ...acc,
          { ...mission, Package: { ...foundPackages.items[idx] } },
        ]
      else return [...acc, current]
    }, [])
  }

  return missionsWithData
}
export const useSearchMissions = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'searchMissions',
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    searchMissions,
    {
      refetchOnWindowFocus: false,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 10,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}

// -----------------------------------

export const getMissions = async ({ queryKey }) => {
  const [, page, { filters = {}, includeModels = [], search } = {}] = queryKey
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  if (search) condition += `&${search}`

  let { data: missions } = await auth({
    method: 'get',
    url: `auth/gamification/missions${condition}`,
  })
  let packages = missions?.items.map((mission) => mission.package_id)

  let allPackagesData
  if (includeModels?.includes('Packages')) {
    if (packages.length) {
      let uniquePackageIds = uniq(packages)
      const packageFilters = generateFilter([], uniquePackageIds, 'id')
      allPackagesData = await getPackages({
        queryKey: [
          null,
          null,
          {
            filters: packageFilters,
          },
        ],
      })
    }
  }

  missions = {
    ...missions,
    items: missions.items.reduce((acc, packageMission) => {
      if (includeModels?.includes('Packages') && allPackagesData.items.length) {
        let idx = allPackagesData.items.findIndex(
          (pData) => pData.id === packageMission.package_id
        )
        if (idx !== -1) {
          packageMission.Package = allPackagesData.items[idx]
        }
      }

      return [...acc, packageMission]
    }, []),
  }

  return missions
}

export const useMissions = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  return useQuery(
    ['missions', paginationIsDisabled ? null : page + 1, { ...queryFilters }],
    getMissions,
    {
      keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 30,
      ...queryOptions,
    }
  )
}
// -----------------------------------
export const getMission = async ({ queryKey }) => {
  const [, id] = queryKey
  const { data } = await auth({
    method: 'get',
    url: `auth/gamification/missions/${id}`,
  })
  return data
}
export const useMission = (id, { queryOptions } = {}) => {
  return useQuery(['mission', id], getMission, {
    ...queryOptions,
  })
}
// -----------------------------------
export const createMission = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/gamification/missions`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useCreateMission = ({ queryOptions } = {}) => {
  return useMutation(createMission, {
    ...queryOptions,
  })
}
// -----------------------------------
export const updateMission = async ({ id, payload }) => {
  const { data } = await auth({
    method: 'put',
    url: `auth/gamification/missions/${id}`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useUpdateMission = ({ queryOptions } = {}) => {
  return useMutation(updateMission, {
    ...queryOptions,
  })
}
// -----------------------------------
export const deleteMission = async (id) => {
  const data = await auth({
    method: 'delete',
    url: `auth/gamification/missions/${id}`,
  })
  return data
}
export const useDeleteMission = ({ queryOptions } = {}) => {
  return useMutation(deleteMission, {
    ...queryOptions,
  })
}
