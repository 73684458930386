/* eslint-disable no-undef */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Logo from 'assets/img/logo.png'
import Button from 'ui/Button'

// Translations / Languages
// import { IconButton, Menu, MenuItem } from '@material-ui/core'
// import GlobeLanguage from '../../assets/svg/language-24px.svg'
// import { useLanguageContext } from 'context/language/language.context'

Top.propTypes = {}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 6),
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    color: theme.palette.common.digidarkblue,
    fontSize: 41,
  },
  link: {
    marginLeft: 'auto',
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  spinner: {
    '&  svg': {
      color: 'white',
    },
  },
  logo: {
    width: 256,
    height: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: 80,
    },
    [theme.breakpoints.down('sm')]: {
      width: 150,
    },
  },
  image: { display: 'inline-block' },
  white: {
    color: 'white',
  },
}))

function Top() {
  const classes = useStyles()

  // Translations / Languages

  // const {
  //   language,
  //   actions: { changeLanguage },
  // } = useLanguageContext()

  // const [anchorEl, setAnchorEl] = React.useState(null)
  // const handleLanguageChange = (lang) => {
  //   changeLanguage(lang)
  // }

  // const handleMenuOpen = (event) => {
  //   setAnchorEl(event.currentTarget)
  // }

  // const handleMenuClose = () => {
  //   setAnchorEl(null)
  // }

  return (
    <div className={classes.root}>
      <Button isImage isLink to="/dashboard">
        <img src={Logo} alt="logo" className={classes.logo} />
      </Button>
      {/* {process.env.REACT_APP_ENV === 'development' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: '16px',
          }}
        >
          <IconButton onClick={handleMenuOpen}>
            <img src={GlobeLanguage} alt="Globe" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            elevation={3}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              selected={language === 'en'}
              onClick={() => handleLanguageChange('en')}
            >
              English
            </MenuItem>
            <MenuItem
              selected={language === 'gr'}
              onClick={() => handleLanguageChange('gr')}
            >
              Greek
            </MenuItem>
          </Menu>
        </div>
      )} */}
    </div>
  )
}

export default Top
