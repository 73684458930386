import React from 'react'
import { NotificationContext } from 'context/Notification'
export default function useNotification() {
  const context = React.useContext(NotificationContext)

  if (context === undefined) {
    throw new Error(
      'useNotification must be used within a NotificationsProvider'
    )
  }

  return context
}
