/* eslint-disable react/prop-types */
import React from 'react'
import * as Sentry from '@sentry/react'
import { ErrorBoundary } from 'react-error-boundary'
import PropTypes from 'prop-types'
import { styled } from '@material-ui/core/styles'
import { Box, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import BG_SVG from './background.svg'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isAuthenticatedSelector } from 'store/auth/selectors'

const ErrorTitle = styled(Typography)(({ theme }) => ({
  fontSize: '71px',
  lineHeight: '73px',
  fontWeight: 700,
  color: 'black',
  [theme.breakpoints.down('lg')]: {
    fontSize: '48px',
    lineHeight: '50px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '36px',
    lineHeight: '38px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '30px',
    lineHeight: '32px',
  },
}))

const ErrorSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '48px',
  lineHeight: '50px',
  fontWeight: 400,
  color: 'black',
  [theme.breakpoints.down('lg')]: {
    fontSize: '40px',
    lineHeight: '42px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '32px',
    lineHeight: '34px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '26px',
    lineHeight: '28px',
  },
}))

const ErrorText = styled(Typography)(({ theme }) => ({
  fontSize: '24px',
  lineHeight: '26px',
  color: 'black',
  whiteSpace: 'pre-line',
  [theme.breakpoints.down('lg')]: {
    fontSize: '20px',
    lineHeight: '22px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    lineHeight: '20px',
  },
}))

const ErrorButton = styled(Button)(() => ({
  fontSize: '20px',
  color: 'white',
  boxShadow: 'none',
  borderRadius: '100vmax',
  background: '#FFC107',
  textTransform: 'none',
  padding: '.3rem 2rem',
  '&:hover': {
    background: '#FFC107',
  },
}))

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('lg')]: {
      padding: '4rem 6rem',
      backgroundSize: 'cover',
    },
    [theme.breakpoints.down('md')]: {
      padding: '3rem 4rem',
      backgroundSize: 'cover',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '3rem 2rem',
    },
  },
}))

FallbackComponent.propTypes = {
  navigateTo: PropTypes.string,
  // resetError: PropTypes.func,
  resetErrorBoundary: PropTypes.func,
  error: PropTypes.instanceOf(Error),
}

function FallbackComponent({ navigateTo = '/dashboard', resetErrorBoundary }) {
  const classes = useStyles()
  const history = useHistory()

  const isAuthenticated = useSelector(isAuthenticatedSelector)

  return (
    <Box
      className={classes.root}
      sx={{
        padding: '8rem 10rem',
        backgroundImage: 'url(' + BG_SVG + ')',
        // backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        // backgroundSize: '100%',
        // backgroundSize: '100% auto',
        flexGrow: 1,
        display: 'flex-item',
        // maxWidth: '100vw',
        minHeight: '100vh',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gap: '20px',
        }}
      >
        <ErrorTitle>Oooops...</ErrorTitle>
        <ErrorSubtitle>Something unexpected happened</ErrorSubtitle>
        <ErrorText>{`The page you are looking for doesn't exist\nor another error occured.`}</ErrorText>
        <ErrorButton
          variant="contained"
          onClick={() => {
            resetErrorBoundary()

            history.push({
              pathname: isAuthenticated ? navigateTo : '/', // perhaps navigate back
              replace: true,
            })
            // history.go(-1)
          }}
          style={{ marginTop: '20px' }}
        >
          Go Back
        </ErrorButton>
      </Box>
    </Box>
  )
}

const logError = (error) => {
  Sentry.captureException(error)
}

function GeneralBoundary(props) {
  React.useLayoutEffect(() => {}, [])
  return (
    <ErrorBoundary
      onError={logError}
      FallbackComponent={(props) => (
        <FallbackComponent navigateTo={props?.navigateTo} {...props} />
      )}
    >
      {props?.children ?? null}
    </ErrorBoundary>
  )
}

export default GeneralBoundary

GeneralBoundary.propTypes = {
  children: PropTypes.node,
  navigateTo: PropTypes.string,
}
