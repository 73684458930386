import React from 'react'
import { Typography, Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { ShoppingCart, GetApp, Mail } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'

Content.propTypes = {}

const useStyles = makeStyles((theme) => ({
  link: {
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 5, 2),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 0, 2),
    },
    cursor: 'pointer',
  },
  text: {
    color: theme.palette.common.digicyan,
  },
  subtext: {
    paddingTop: 10,
    color: theme.palette.text.secondary,
  },
  icon: {
    fontSize: 70,
    marginBottom: theme.spacing(1),
    color: theme.palette.secondary.main,
    margin: '0 auto',
  },
  gridWrapper: {
    padding: theme.spacing(0, 4),
    marginTop: 242,
    paddingBottom: 50,
    display: 'grid',
    gap: 10,
    gridAutoRows: '1fr',
    gridTemplateColumns: 'repeat(3, minmax(10px, 1fr))',
    // gridTemplateAreas: `"header header header header"
    // "main main . sidebar"
    // "footer footer footer footer"`,
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, minmax(10px, 1fr))',
      marginTop: 150,
      gap: 5,
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, minmax(10px, 1fr))',
      marginTop: 0,
      gap: 0,
    },
  },
}))

function Content() {
  const { t } = useTranslation()

  const columns = [
    {
      title: t('main.landing.sections.content.downloadSection.title'),
      text: t('main.landing.sections.content.downloadSection.description'),
      icon: GetApp,
      to: '/download',
    },
    {
      title: t('main.landing.sections.content.buySection.title'),
      text: t('main.landing.sections.content.buySection.description'),
      icon: ShoppingCart,
      to: 'www.expresspublishing.co.uk/en-gr/search?product_type=DigiBooks-and-DigiIWB',
      externalLink: true,
    },
    {
      title: t('main.landing.sections.content.contactSection.title'),
      text: t('main.landing.sections.content.contactSection.description'),
      icon: Mail,
      to: '/contact',
    },
  ]

  const classes = useStyles()

  return (
    <div className={classes.gridWrapper}>
      {columns.map((column) => {
        return !column?.externalLink ? (
          <Link
            key={column.title}
            component={RouterLink}
            style={{ textDecoration: 'none' }}
            to={column.to ?? '#'}
            className={classes.link}
          >
            <column.icon className={classes.icon} />
            <Typography
              align="center"
              component="span"
              display="block"
              variant="h3"
              className={classes.text}
            >
              {column.title}
            </Typography>
            <Typography
              component="span"
              display="block"
              variant="h5"
              align="center"
              className={classes.subtext}
            >
              {column.text}
            </Typography>
          </Link>
        ) : (
          <Link
            key={column.title}
            target={'_blank'}
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
            href={`//${column.to}`}
            className={classes.link}
          >
            <column.icon className={classes.icon} />
            <Typography
              align="center"
              component="span"
              display="block"
              variant="h3"
              className={classes.text}
            >
              {column.title}
            </Typography>
            <Typography
              component="span"
              display="block"
              variant="h5"
              align="center"
              className={classes.subtext}
            >
              {column.text}
            </Typography>
          </Link>
        )
      })}
    </div>
  )
}

export default Content
