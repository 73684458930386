import React from 'react'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import OwlImage from 'assets/img/owl.png'

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    margin: theme.spacing(2, 0),
  },
  paper: {
    padding: theme.spacing(4, 0, 0),
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.common.digipurple,
    },
  },
  title: {
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.common.digipurple,
      textAlign: 'center',
    },
  },
  text: {
    lineHeight: '33px',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.text.primary,
    },
    whiteSpace: 'pre-line',
  },
  owl: {
    width: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      fill: theme.palette.common.digipurple,
      display: 'none',
    },
  },
}))
LearnMore.propTypes = {}

function LearnMore() {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Paper className={classes.paper} elevation={0}>
      <img src={OwlImage} className={classes.owl} />
      <Typography
        component="span"
        display="block"
        variant="h1"
        className={classes.title}
      >
        {t('main.landing.sections.learnMore.title')}
      </Typography>
      <Typography
        component="span"
        display="block"
        variant="h5"
        className={classes.text}
      >
        {t('main.landing.sections.learnMore.description')}
      </Typography>
    </Paper>
  )
}

export default LearnMore
