import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Top from './Top'
import Footer from './Footer'
import Login from './Login'
import LearnMore from './LearnMore'
import Content from './Content'

import LandingImage from 'assets/img/LoginBackground_1920x1080.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundImage: 'url(' + LandingImage + ')',
    backgroundRepeat: 'no-repeat',

    [theme.breakpoints.down('sm')]: {
      backgroundSize: '100% auto',
    },
  },
  wrapper: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'column-reverse wrap',
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 4),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 132,
      paddingRight: 132,
    },
    [theme.breakpoints.up('xl')]: {
      paddingLeft: 232,
      paddingRight: 232,
    },
    marginTop: 143,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  side: {
    width: '40%',
    [theme.breakpoints.down('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  form: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: 500,
      width: '100%',
    },
    width: 500,
  },
}))

Main.propTypes = {}

function Main() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Top />
      <div className={classes.wrapper}>
        <div className={classes.side}>
          <LearnMore />
        </div>
        <div className={classes.form}>
          <Login />
        </div>
      </div>
      <Content />
      <Footer />
    </div>
  )
}

export default Main
