import DateTime from 'luxon/src/datetime.js'

export function parseSecondsToIsoDate(value) {
  let dt = DateTime.fromSeconds(value).toISODate()
  return dt
}

export function parseSecondsToIso(value) {
  let dt = DateTime.fromSeconds(value).toISO()
  return dt
}

export function parseDateToSeconds(value) {
  let dt = DateTime.fromISO(value)
  return dt.toSeconds()
}
