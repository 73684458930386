/* eslint-disable no-undef */
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { QueryClientProvider } from 'react-query'
import { ThemeProvider } from '@material-ui/core/styles'
import { LinearProgress, CssBaseline } from '@material-ui/core'
import { ReactQueryDevtools } from 'react-query/devtools'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Router } from 'react-router-dom'
import App from './App'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { queryClient } from 'api/query'
import { NotificationContextProvider } from 'context/Notification'
import { UserRoleContextProvider } from 'context/user/userRole.context.jsx'

import theme from './theme'
import history from 'router'
import store, { persistor } from './store'
import './assets/css/main.css'
import './i18n'
import '../node_modules/flag-icon-css/css/flag-icon.css'
import 'assets/perfect_scrollbar.css'
import { LanguageContextProvider } from 'context/language/language.context'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

Sentry.init({
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_VERSION,
  dsn: 'https://b126a6b4422a4f5588cb446bb9f157f8@o308884.ingest.sentry.io/5544412',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  maxBreadcrumbs: 10,
  // debug: process.env.REACT_APP_ENV === 'production' ? false : true,
  debug: false,
  normalizeDepth: 100,
  normalizeMaxBreadth: 2000,
  tracesSampleRate: 0.2,
  // beforeSend: (event) => {
  //   console.log('event', event)
  //   return event
  // },
  ignoreErrors: [
    /(?:^|\W)Non-Error promise rejection captured.*/,
    /(?:^|\W)Missing required parameters: sitekey.*/,
    /(?:^|\W)Unexpected token 'r', "recaptcha-setup" is not valid JSON.*/,
    /(?:^|\W)Unexpected token r in JSON at position 0*/,
    /(?:^|\W)Unexpected character at line 1 column 1 of the JSON data*/,
  ],
})

ReactDOM.render(
  <GoogleReCaptchaProvider
    useRecaptchaNet="true"
    reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
  >
    <ThemeProvider theme={theme}>
      <Suspense fallback={<LinearProgress />}>
        <QueryClientProvider client={queryClient}>
          <NotificationContextProvider>
            <UserRoleContextProvider>
            <Provider store={store}>
              <PersistGate loading={<LinearProgress />} persistor={persistor}>
                <Router history={history}>
                  <CssBaseline />

                  <LanguageContextProvider>
                    <App />
                  </LanguageContextProvider>
                </Router>
              </PersistGate>
            </Provider>
            </UserRoleContextProvider>
          </NotificationContextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Suspense>
    </ThemeProvider>
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
)
