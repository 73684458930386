import get from 'lodash/get'

export function addToCondition(url = '', data, key = 'missingKey') {
  let prev = url
  if (data != null) {
    if (typeof data !== 'object') {
      prev += `&${key}=${data}`
    } else if (typeof data === 'object' && data.length) {
      data.forEach((value) => {
        prev += `&${key}=${value}`
      })
    }
  }
  return prev
}
export function parseFilters(filters, url = '') {
  let temp = url
  if (filters?.length) {
    filters.forEach((filter) => {
      temp += `&${filter.by}=${filter.value}`
    })
  }
  return temp
}
export function parseSearch(url = '', searchModel = {}, path = '') {
  let temp = url
  let targetModel = get(searchModel, path, undefined)
  if (!targetModel) return
  let searchValues = Object.values(targetModel)
  if (searchValues?.length) {
    searchValues.forEach((field) => {
      temp += `&${field}`
    })
  }
  return temp
}
export function parsePage(page, url = '') {
  let temp = url
  if (page != null) {
    temp += `?page=${page}`
  } else temp += `?per_page=0`
  return temp
}
export function generateFilter(previous = [], data = [], key) {
  if (!data || !key || typeof key !== 'string') return
  let filters = []
  if (data.constructor === Array) {
    filters = data.map((value) => {
      if (value)
        return {
          by: key,
          value: value,
        }
    })
  } else if (['string', 'number'].includes(typeof data)) {
    filters.push({
      by: key,
      value: data,
    })
  }
  return [...previous, ...filters]
}
