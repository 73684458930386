import React, { useReducer, createContext } from 'react'
import PropTypes from 'prop-types'
import {
  SET_FIELD,
  VALIDATE_PASSWORD,
  SET_ERROR,
  SET_EMAIL,
  PREV,
  NEXT,
  SET_STEPS,
  SET_RETRIEVAL_METHOD,
  CHECK_PASSWORD_MATCH,
} from './types'
import { reducer } from './reducer'

const initialState = {
  steps: 1,
  currentStep: 0,
  retrievalMethod: '',
  loading: {
    email: false,
  },
  error: {
    username: '',
    email: '',
    redeemCode: '',
    usernameOrActivation: false,
  },
  username: '',
  password: '',
  confirmPassword: '',
  email: '',
  redeemCode: '',
  activationCode: '',
  passwordValid: false,
  passwordValidMatch: false,
}
const ForgotPasswordContext = createContext()

ForgotPasswordContext.displayName = 'ForgotPasswordContext'

const ForgotPasswordContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setRetrievalMethod = (value) => {
    dispatch({ type: SET_RETRIEVAL_METHOD, payload: { value } })
  }

  const setSteps = (value) => {
    dispatch({ type: SET_STEPS, payload: { value } })
  }

  const setCurrentStep = (value) => {
    dispatch({
      type: SET_FIELD,
      payload: { name: 'currentStep', value },
    })
  }

  const setEmail = (value) => {
    dispatch({ type: SET_EMAIL, payload: { value } })
  }

  const setError = (field, value) => {
    dispatch({
      type: SET_ERROR,
      payload: {
        name: field,
        value: value,
      },
    })
  }

  const setUsername = (value) => {
    dispatch({
      type: SET_FIELD,
      payload: { name: 'username', value },
    })
  }

  const setRedeemCode = (value) => {
    dispatch({
      type: SET_FIELD,
      payload: { name: 'redeemCode', value },
    })
  }

  const setActivationCode = (value) => {
    dispatch({
      type: SET_FIELD,
      payload: { name: 'activationCode', value },
    })
  }

  const setConfirmPassword = (value) => {
    dispatch({
      type: SET_FIELD,
      payload: { name: 'confirmPassword', value },
    })
  }

  const setPassword = (value) => {
    dispatch({
      type: SET_FIELD,
      payload: { name: 'password', value },
    })
  }

  const setPasswordValid = (value) => {
    dispatch({
      type: SET_FIELD,
      payload: { name: 'usernameValid', value },
    })
  }

  const setPasswordValidMatch = (value) => {
    dispatch({
      type: SET_FIELD,
      payload: { name: 'passwordValidMatch', value },
    })
  }

  const validatePassword = (pw) => {
    dispatch({
      type: VALIDATE_PASSWORD,
      payload: { value: pw },
    })
  }

  const validatePasswordMatch = (confirm, password) => {
    dispatch({
      type: CHECK_PASSWORD_MATCH,
      payload: { password, confirm },
    })
  }

  const nextStep = () => {
    dispatch({ type: NEXT })
  }
  const prevStep = () => {
    dispatch({ type: PREV })
  }

  return (
    <ForgotPasswordContext.Provider
      value={{
        ...state,
        validatePasswordMatch,
        setUsername,
        setPassword,
        setConfirmPassword,
        setPasswordValid,
        setPasswordValidMatch,
        validatePassword,
        setSteps,
        setCurrentStep,
        nextStep,
        prevStep,
        setEmail,
        setError,
        setActivationCode,
        setRetrievalMethod,
        setRedeemCode,
      }}
    >
      {children}
    </ForgotPasswordContext.Provider>
  )
}
ForgotPasswordContextProvider.propTypes = {
  children: PropTypes.node,
}

export { ForgotPasswordContext, ForgotPasswordContextProvider }
