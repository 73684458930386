import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Toolbar, LinearProgress } from '@material-ui/core'
import clsx from 'clsx'
import ReaderDrawer from './drawer-container.component'
import ReaderAppbar from './reader-appbar.component'
import DrawerContent from './drawer-content.component'
import ReaderFrame from './reader-frame.component'
import { useReaderContext } from './context/reader.context'
import { useDigipubState } from './use-digipub-state.hook'

const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menuButton: {},
  hide: {
    display: 'none',
  },
  main: {},
  mainShift: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  title: {},
  header: {
    fontSize: 30,
    fontWeight: 300,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iframe: {
    width: 1060,
    height: `calc(100vh - ${62}px)`,
  },
  iframeShift: {},
}))

export const ReaderDigipub = () => {
  const classes = useStyles()

  const {
    iRef,
    digipub: { url },
    drawer: { open },
  } = useReaderContext()

  const {
    ui: { hasNext, hasPrev },
    actions: {
      handleLoad,
      handleToggleDrawer,
      handleNext,
      handlePrev,
      handleClickExercise,
    },
    hasLoaded,
    calculatedMargin,
  } = useDigipubState()

  return (
    <div className={classes.root}>
      <ReaderAppbar
        onOpen={handleToggleDrawer}
        onNext={handleNext}
        onPrev={handlePrev}
        hasNext={hasNext}
        hasPrev={hasPrev}
      />
      <ReaderDrawer>
        <DrawerContent onExerciseClick={handleClickExercise} />
      </ReaderDrawer>
      <main
        className={clsx(classes.main, {
          [classes.mainShift]: open,
        })}
      >
        <Toolbar />
        {!hasLoaded ? <LinearProgress style={{ width: '1920px' }} /> : null}
        <ReaderFrame
          ref={iRef}
          variant={'epub3'}
          loaded={hasLoaded}
          frameSrc={url}
          frameMargin={calculatedMargin}
          onLoad={handleLoad}
        />
      </main>
    </div>
  )
}
