import React from 'react'
import { ReaderContent } from './reader-content.component'
import { ReaderContextProvider } from './context/reader.context'

function ReaderMain() {
  return (
    <ReaderContextProvider>
      <ReaderContent />
    </ReaderContextProvider>
  )
}

ReaderMain.propTypes = {}

export default ReaderMain
