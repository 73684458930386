import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grow } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {},
  frameWrapper: {
    height: `calc(100vh - ${62}px)`,
  },
  epub3: {
    paddingTop: theme.spacing(1),
    width: 1060,
  },
  html5: {},
  iframe: {
    width: '100%',
    height: '100%',
  },
}))

const ReaderFrame = React.forwardRef(
  (
    {
      variant = 'epub3',
      frameSrc = '',
      loaded = false,
      frameMargin = '0px',
      onLoad = () => {},
    },
    ref
  ) => {
    const classes = useStyles()

    return (
      <>
        {frameSrc ? (
          <div
            className={clsx(classes.frameWrapper, {
              [classes.epub3]: variant === 'epub3',
              [classes.html5]: variant === 'html5',
            })}
          >
            <Grow in={loaded}>
              <iframe
                ref={ref}
                id="iframe"
                name="iframe"
                loading="lazy"
                frameBorder="0"
                onLoad={onLoad}
                src={frameSrc}
                style={{
                  marginLeft: frameMargin,
                }}
                allowfullscreen="true"
                className={clsx(classes.iframe)}
              />
            </Grow>
          </div>
        ) : null}
      </>
    )
  }
)

ReaderFrame.displayName = ReaderFrame

ReaderFrame.propTypes = {
  loaded: PropTypes.bool,
  frameSrc: PropTypes.string,
  frameMargin: PropTypes.string,
  onLoad: PropTypes.func,
  variant: PropTypes.string,
}

export default ReaderFrame
