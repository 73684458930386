import React from 'react'
import { CircularProgress, Grid } from '@material-ui/core'

import BaseBackdrop from 'ui/BaseBackdrop'
import { useReaderContext } from './context/reader.context'

export const ReaderLoader = () => {
  const {
    status: { IS_READING },
  } = useReaderContext()
  return (
    <BaseBackdrop open={IS_READING}>
      <Grid container direction="row" justifyContent="center" spacing={3}>
        <Grid item xs={12} container direction="row" justifyContent="center">
          <CircularProgress color="inherit" size={50} />
        </Grid>
        <Grid item xs={12} container direction="row" justifyContent="center">
          Please wait
        </Grid>
      </Grid>
    </BaseBackdrop>
  )
}
