import React from 'react'
import { Container } from '@material-ui/core'

import Bottom from 'components/Main/Footer/'
import Top from 'components/Main/Top'
import PasswordChange from 'components/PasswordChange'
import LandingImage from 'assets/img/LoginBackground_1920x1080.jpg'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundImage: 'url(' + LandingImage + ')',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      minHeight: 600,
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 900,
      backgroundSize: 'cover',
    },
    [theme.breakpoints.up('lg')]: {
      backgroundSize: 1920,
      minHeight: 1080,
    },
  },
}))

function PasswordChangePage() {
  const classes = useStyles()

  return (
    <>
      <div className={classes.root}>
        <Top />
        <Container>
          <PasswordChange />
        </Container>
      </div>
      <Bottom />
    </>
  )
}

export default PasswordChangePage
