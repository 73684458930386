import {
  SET_URL,
  SET_ACTIVITY,
  SET_TOC,
  SET_SPINE,
  SET_PACKAGE,
  SET_READ_TYPE,
  SET_ACTIVITIES,
} from './types'

export const setPackage = (payload) => (dispatch) => {
  dispatch({
    type: SET_PACKAGE,
    payload,
  })
}
export const setReadType = (type) => (dispatch) => {
  dispatch({
    type: SET_READ_TYPE,
    payload: { value: type },
  })
}
export const setActivities = (activities) => (dispatch) => {
  dispatch({
    type: SET_ACTIVITIES,
    payload: activities,
  })
}

export const setURL = (url) => (dispatch) => {
  dispatch({
    type: SET_URL,
    payload: { value: url },
  })
}

export const setActivity = (id) => (dispatch) => {
  dispatch({
    type: SET_ACTIVITY,
    payload: { value: id },
  })
}
export const setToc = (payload) => (dispatch) => {
  dispatch({
    type: SET_TOC,
    payload,
  })
}

export const setSpine = (payload) => (dispatch) => {
  dispatch({
    type: SET_SPINE,
    payload,
  })
}
