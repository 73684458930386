/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Drawer, useTheme } from '@material-ui/core'

import Button from 'ui/Button'

import clsx from 'clsx'
import Logo from 'assets/img/logo.png'
import { useReaderContext } from './context/reader.context'
import { useMemo } from 'react'

const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
  },
  paper: {
    backgroundColor: theme.palette.common.digifooter1,
  },
  image: {
    maxWidth: 220,
  },
  imageWrapper: {
    padding: theme.spacing(2),
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tab: {
    width: '100%',
  },
  selectedTab: {
    color: theme.palette.primary.main,
  },
  tabWrapper: {
    margin: theme.spacing(0, 0, 0),
  },
  notSelectedTabWrapper: {
    borderBottom: `3px solid transparent`,
  },
  selectedTabWrapper: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
}))

const ReaderDrawer = ({ children }) => {
  const classes = useStyles()
  const contentRef = React.useRef()
  const theme = useTheme()

  const {
    info: { pages, tabs, currentTab },
    drawer: { open },
    actions: { setCurrentTab },
  } = useReaderContext()

  const hasPages = useMemo(() => !!pages?.length, [pages])

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx(classes.paper, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      PaperProps={{ ref: contentRef }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid item className={classes.imageWrapper}>
          <img src={Logo} alt="logo" className={classes.image} />
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        style={{
          paddingBottom: 10,
          position: 'sticky',
          top: 0,
          backgroundColor: theme.palette.common.digifooter1,
          zIndex: 1000,
        }}
      >
        {tabs
          ?.filter((option) => (!hasPages ? option.text !== 'pages' : true))
          .map((page, index) => (
            <Grid
              item
              xs={12}
              key={index}
              className={clsx(classes.tabWrapper, [
                index === currentTab
                  ? classes.selectedTabWrapper
                  : classes.notSelectedTabWrapper,
              ])}
            >
              <Button
                className={clsx(classes.tab)}
                color={index === currentTab ? 'primary' : 'text'}
                onClick={() => setCurrentTab(index)}
              >
                {page.text}
              </Button>
            </Grid>
          ))}
      </Grid>
      {children}
    </Drawer>
  )
}

export default React.memo(ReaderDrawer)
