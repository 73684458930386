import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'

import { isAuthenticatedSelector, userRoleSelector } from 'store/auth/selectors'

// Create Custom Sentry Route component
const SentryRoute = Sentry.withSentryRouting(Route)

function AuthenticatedRoute({ children, access, restrict, allow, ...rest }) {
  const isAuthenticated = useSelector(isAuthenticatedSelector)
  const userRole = useSelector(userRoleSelector)

  return (
    <SentryRoute
      {...rest}
      render={({ location }) => {
        if (!isAuthenticated) {
          return <Redirect to={{ pathname: '/', state: { from: location } }} />
        } else if (
          (access &&
            !access.includes(userRole) &&
            allow &&
            !allow.includes(userRole)) ||
          (restrict && restrict.includes(userRole))
        ) {
          return (
            <Redirect
              to={{ pathname: '/dashboard', state: { from: location } }}
            />
          )
        } else {
          return children
        }
      }}
    />
  )
}

export default AuthenticatedRoute

AuthenticatedRoute.propTypes = {
  children: PropTypes.node,
  restrict: PropTypes.array,
  access: PropTypes.array,
  allow: PropTypes.array,
}
