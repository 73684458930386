import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from '@material-ui/core/styles'
import clsx from 'clsx'
import {
  Paper,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
  FormGroup,
  Slide,
  Link,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import useStorage from 'hooks/useStorage'
import Button from 'ui/Button'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 12, 3),
    backgroundColor: theme.palette.common.digifooter1,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
  },
  title: {
    // whiteSpace: 'nowrap',
  },
  link: {
    color: theme.palette.common.digiblue,
  },
  buttons: {
    margin: theme.spacing(2, 0),
  },
  buttonAccept: {
    borderRadius: 50,
    padding: theme.spacing(1, 3),
  },
  buttonManage: {
    borderRadius: 50,
    padding: theme.spacing(1, 3),
    marginRight: theme.spacing(4),
  },
  preferences: {
    alignSelf: 'flex-start',
  },
  checkbox: {
    margin: theme.spacing(1, 0),
  },
}))

const theme = createTheme({
  palette: {
    secondary: {
      main: '#61be2d',
    },
  },
})

export default function PrivacyPopup() {
  const classes = useStyles()
  const { t } = useTranslation()
  const [accepted, setAccepted] = useStorage('digiacceptpolicy', '', 'local')

  let [showPref, setShowPref] = useState(false)
  const [acceptRequired] = useState(true)
  const [accept3rdParty, setAccept3rdParty] = useState(true)

  const handleAccept = () => {
    setAccepted(true)
  }

  const toggleChecked = () => {
    setAccept3rdParty((prev) => !prev)
  }

  const handleManageClick = () => {
    setShowPref((prev) => !prev)
  }

  return (
    <>
      <Slide
        direction="up"
        in={!accepted}
        mountOnEnter
        unmountOnExit
        {...(!accepted ? { timeout: 2000 } : {})}
      >
        {true && (
          <Paper className={classes.root} elevation={3}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              className={clsx(classes.margin)}
            >
              <Grid item>
                <Typography
                  component="span"
                  variant="subtitle1"
                  className={classes.title}
                >
                  {t('privacyPopup.prompt1')}
                  {` `}
                  <Link
                    component={RouterLink}
                    to="/privacy"
                    style={{ textDecoration: 'none' }}
                  >
                    <Typography
                      component="span"
                      variant="subtitle1"
                      className={classes.link}
                    >
                      {t('privacyPopup.prompt2')}
                    </Typography>
                  </Link>
                  {` `}
                  {t('privacyPopup.prompt3')}
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                wrap="nowrap"
                justifyContent="flex-start"
                alignItems="center"
                //   spacing={2}
                className={classes.buttons}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="blue"
                    className={classes.buttonManage}
                    onClick={handleManageClick}
                  >
                    {t('privacyPopup.manageBtn')}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="green"
                    className={classes.buttonAccept}
                    onClick={handleAccept}
                  >
                    {t('privacyPopup.acceptBtn')}
                  </Button>
                </Grid>
              </Grid>
              {showPref && (
                <Grid item className={classes.preferences}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={acceptRequired}
                          className={classes.checkbox}
                        />
                      }
                      disabled
                      label={t('privacyPopup.reqCookies')}
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      control={
                        <ThemeProvider theme={theme}>
                          <Switch
                            size="small"
                            checked={accept3rdParty}
                            onChange={toggleChecked}
                          />
                        </ThemeProvider>
                      }
                      label={t('privacyPopup.3rdPartyCookies')}
                      labelPlacement="start"
                    />
                  </FormGroup>
                </Grid>
              )}
            </Grid>
          </Paper>
        )}
      </Slide>
      {/* </Container> */}
    </>
  )
}

PrivacyPopup.propTypes = {}
