import { useQuery, useMutation } from 'react-query'

import auth from 'api'
import { parseFilters, parsePage, parseSearch } from 'helpers/api'
import { getClassUsers } from '../ClassUsers/queries'

export const searchTableClasses = async ({ queryKey }) => {
  const [, page, { filters = [], searchModels = {} } = {}] = queryKey

  let classesSearch
  if (searchModels['Classes']) {
    classesSearch = parseSearch('', searchModels, 'Classes')
  }
  const foundClasses = await getClasses({
    queryKey: [
      null,
      page,
      {
        filters: filters,
        search: classesSearch,
      },
    ],
  })
  return foundClasses
}
export const useSearchTableClasses = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'searchClasses',
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    searchTableClasses,
    {
      refetchOnWindowFocus: false,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 10,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}

//  --------------------------------------------------

export const getClasses = async ({ queryKey }) => {
  const [, page, { filters = [], includeModels = [], search } = {}] = queryKey
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)

  if (search) condition += `&${search}`

  const { data: classes } = await auth({
    method: 'get',
    url: `auth/school/classes${condition}`,
  })

  let classesWithEnrollData = {
    ...classes,
    items: classes.items.reduce((acc, cls) => {
      let schoolClass = cls
      schoolClass.Enroll = {
        enrollable: cls?.enrollable,
        class_code: cls?.class_code,
      }
      return [...acc, schoolClass]
    }, []),
  }

  let classWithData = classesWithEnrollData
  // this models fetches total count of students per class
  if (includeModels?.includes('ClassUsersCount')) {
    const classIds = classWithData?.items.map((schoolClass) => schoolClass.id)
    if (classIds?.length) {
      for (let i = 0; i < classIds.length; i++) {
        let classUsersData = await getClassUsers({
          queryKey: [
            null,
            classIds[i],
            null,
            {
              includeModels: ['Users'],
            },
          ],
        })
        let idx = classWithData.items.findIndex(
          (item) => item?.id === classIds[i]
        )
        classWithData.items[idx].ClassUsersCount = {
          count: classUsersData?.total_items ?? 0,
        }
        classWithData.items[idx].ClassUsers = {
          users: classUsersData?.items,
        }
      }
    }
  }

  return classWithData
}

export const useClasses = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    ['classes', paginationIsDisabled ? null : page + 1, { ...queryFilters }],
    getClasses,
    {
      keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 60,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}

// -----------------------------------
export const getClass = async ({ queryKey }) => {
  const [, id] = queryKey
  const { data } = await auth({
    method: 'get',
    url: `auth/school/classes/${id}`,
  })
  return data
}
export const useClass = (id, { queryOptions } = {}) => {
  return useQuery(['class', id], getClass, {
    ...queryOptions,
  })
}
// -----------------------------------
export const createClass = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/school/classes`,
    data: {
      class: {
        ...payload,
      },
    },
  })
  return data
}
export const useCreateClass = ({ queryOptions } = {}) => {
  return useMutation(createClass, {
    ...queryOptions,
  })
}
// -----------------------------------
export const updateClass = async ({ id, payload }) => {
  const { data } = await auth({
    method: 'put',
    url: `auth/school/classes/${id}`,
    data: {
      class: {
        ...payload,
      },
    },
  })
  return data
}
export const useUpdateClass = ({ queryOptions } = {}) => {
  return useMutation(updateClass, {
    ...queryOptions,
  })
}
// -----------------------------------
export const deleteClass = async (id) => {
  await auth({
    method: 'delete',
    url: `auth/school/classes/${id}`,
  })
}
export const useDeleteClass = ({ queryOptions } = {}) => {
  return useMutation(deleteClass, {
    ...queryOptions,
  })
}
