import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box } from '@material-ui/core'
import gdpr from 'assets/img/gdpr.png'
import secure from 'assets/img/secure.png'
import goldAward from 'assets/img/Gold_award.png'
import silverAward from 'assets/img/silver_award.png'
import clsx from 'clsx'

Images.propTypes = {}

const useStyles = makeStyles((theme) => ({
  root: {
    clear: 'both',
    padding: theme.spacing(2),
  },
  gridWrapper: {
    display: 'grid',
    justifyContent: 'center',
    gap: 20,
    alignItems: 'center',
    alignContent: 'center',
    gridTemplateAreas: `'silver gold other'`,
    [theme.breakpoints.down('xs')]: {
      gridTemplateAreas: `'silver'
      'gold'
      'other'`,
    },
  },
  otherGridWrapper: {
    gridArea: 'other',
    display: 'grid',
    justifyContent: 'center',
    gap: 20,
    gridAutoRows: '1fr',
    gridTemplateAreas: `'gdpr'
                        'secured'`,
    [theme.breakpoints.down('sm')]: {
      gridTemplateAreas: `'gdpr secured'`,
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateAreas: `'gdpr'
      'secured'`,
    },
  },
  award_imgs: {
    margin: theme.spacing(4, 0),
    height: '50%',
  },
  gdpr: {
    gridArea: 'gdpr',
  },
  secured: {
    gridArea: 'secured',
  },
  gold: {
    gridArea: 'gold',
  },
  silver: {
    gridArea: 'silver',
  },
  img: {
    height: '100%',
    maxHeight: 70,
    width: 'auto',
  },
}))

function Images() {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.gridWrapper}>
        <Box
          component={'img'}
          src={silverAward}
          className={clsx(classes.img, classes.silver)}
        />
        <Box
          component={'img'}
          src={goldAward}
          className={clsx(classes.img, classes.gold)}
        />
        <Box className={classes.otherGridWrapper}>
          <Box
            component={'img'}
            src={gdpr}
            className={clsx(classes.img, classes.gdpr)}
          />
          <Box
            component={'img'}
            src={secure}
            className={clsx(classes.img, classes.secured)}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Images
