export const INDTEACHER = 'Individual Teacher'
export const ADMIN = 'Admin'
export const MEMBER = 'Member'
export const STUDENT = 'Student'
export const TEACHER = 'Teacher'
export const SCHOOLMANAGER = 'School Manager'
export const GUARDIAN = 'Guardian'
export const CODEMANAGER = 'Code Manager'
export const TESTER = 'Tester'
export const MODERATOR = 'Moderator'
export const MASTER = 'School Master'
export const PROJECTMANAGER = 'Project Manager'
export const EDITOR = 'Editor'
export const SALESMANAGER = 'Sales'

export default {
  INDTEACHER,
  ADMIN,
  MEMBER,
  STUDENT,
  TEACHER,
  SCHOOLMANAGER,
  GUARDIAN,
  TESTER,
  MODERATOR,
  MASTER,
  PROJECTMANAGER,
  EDITOR,
  SALESMANAGER,
}

export const ROLES = {
  INDTEACHER,
  ADMIN,
  MEMBER,
  STUDENT,
  TEACHER,
  SCHOOLMANAGER,
  GUARDIAN,
  TESTER,
  MODERATOR,
  MASTER,
  PROJECTMANAGER,
  EDITOR,
  SALESMANAGER,
}

export const PASSWORD_PATTERN =
  /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{6,})/
