import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { isAuthenticatedSelector } from 'store/auth/selectors'
import * as Sentry from '@sentry/react'

GuestRoute.propTypes = {
  children: PropTypes.node,
}

const SentryRoute = Sentry.withSentryRouting(Route)

function GuestRoute({ children, ...rest }) {
  const isAuthenticated = useSelector(isAuthenticatedSelector)

  return (
    <SentryRoute
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{ pathname: '/dashboard', state: { from: location } }}
          />
        )
      }
    />
  )
}

export default GuestRoute
