/* eslint-disable no-undef */
import React from 'react'
import PropTypes from 'prop-types'

const useTitle = () => {
  const [title, setTitle] = React.useState(() => {
    // if (process.env.NODE_ENV === 'development')
    //   return process.env.REACT_APP_NAME
    // else return $REACT_APP_NAME
    return process.env.REACT_APP_NAME
  })

  const transformedPath = (title) => {
    return title
      .split('-')
      .filter((path) => (path ? path : undefined))
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .reduce((acc, item) => acc.concat(` ${item}`), '')
  }

  const addToTitle = (page) => {
    document.title = `${transformedPath(page)} - ${title}`
  }
  const resetTitle = () => {
    document.title = title
  }

  return { title, setTitle, addToTitle, resetTitle }
}
export default useTitle

useTitle.propTypes = {
  title: PropTypes.string,
}
