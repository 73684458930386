import { useMutation, useQuery } from 'react-query'

import auth from 'api'
import { parseFilters, parsePage, parseSearch } from 'helpers/api'

export const searchTeacherClasses = async ({ queryKey }) => {
  const [, page, { filters = [], searchModels = {} } = {}] = queryKey

  let classesSearch
  if (searchModels['Classes']) {
    classesSearch = parseSearch('', searchModels, 'Classes')
  }
  const foundClasses = await getTeacherClasses({
    queryKey: [
      null,
      page,
      {
        filters: filters,
        search: classesSearch,
      },
    ],
  })
  return foundClasses
}
export const useSearchTeacherClasses = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'searchTeacherClasses',
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    searchTeacherClasses,
    {
      refetchOnWindowFocus: false,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 10,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}

//  --------------------------------------------------

export const getTeacherClasses = async ({ queryKey }) => {
  const [, page, { filters, search } = {}] = queryKey
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  if (search) condition += `&${search}`
  const { data: classes } = await auth({
    method: 'get',
    url: `auth/individual-teacher/classes${condition}`,
  })
  let classesWithEnrollData
  classesWithEnrollData = {
    ...classes,
    items: classes.items.reduce((acc, cls) => {
      let schoolClass = { ...cls }
      schoolClass.Enroll = {
        enrollable: cls.enrollable,
        class_code: cls.class_code,
      }
      return [...acc, schoolClass]
    }, []),
  }

  return classesWithEnrollData
}

export const useTeacherClasses = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'teacherClasses',
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    getTeacherClasses,
    {
      keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 60,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}

// -----------------------------------
export const getTeacherClass = async ({ queryKey }) => {
  const [, id] = queryKey
  const { data } = await auth({
    method: 'get',
    url: `auth/individual-teacher/classes/${id}`,
  })
  return data
}
export const useTeacherClass = (id, { queryOptions } = {}) => {
  return useQuery(['teacherClass', id], getTeacherClass, {
    ...queryOptions,
  })
}
// -----------------------------------
export const createTeacherClass = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/individual-teacher/classes`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useCreateTeacherClass = ({ queryOptions } = {}) => {
  return useMutation(createTeacherClass, {
    ...queryOptions,
  })
}
// -----------------------------------
export const updateTeacherClass = async ({ id, payload }) => {
  const { data } = await auth({
    method: 'put',
    url: `auth/individual-teacher/classes/${id}`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useUpdateTeacherClass = ({ queryOptions } = {}) => {
  return useMutation(updateTeacherClass, {
    ...queryOptions,
  })
}
// -----------------------------------
export const deleteTeacherClass = async (id) => {
  await auth({
    method: 'delete',
    url: `auth/individual-teacher/classes/${id}`,
  })
}
export const useDeleteTeacherClass = ({ queryOptions } = {}) => {
  return useMutation(deleteTeacherClass, {
    ...queryOptions,
  })
}
