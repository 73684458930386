import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Backdrop, CircularProgress } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  root: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: theme.palette.common.white,
  },
}))

function BaseBackdrop({ children, open = false, onClose = () => {} }) {
  const classes = useStyle()
  return (
    <Backdrop className={classes.backdrop} open={open} onClose={onClose}>
      {children ? children : <CircularProgress color="inherit" />}
    </Backdrop>
  )
}

export default BaseBackdrop

BaseBackdrop.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
}
