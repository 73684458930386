/* eslint-disable no-undef */
import React from 'react'
import { Grid, Typography, Tooltip, IconButton, Link } from '@material-ui/core'
import { YouTube, Twitter, Instagram, Facebook } from '@material-ui/icons'
import LogoXWhite from '../../../assets/img/x-logo-white.png'
import { makeStyles } from '@material-ui/core/styles'
import RecaptchaIcon from 'assets/img/recaptcha.png'
import { useTranslation } from 'react-i18next'
BottomCopyright.propTypes = {}

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    position: 'relative',
    backgroundColor: '#363636',
    color: 'white',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      padding: theme.spacing(2, 0),
    },
  },
  icons: {
    fontSize: 30,
    color: 'white',
  },
  twitterIcon: {
    width: '23px',
    height: '23px',
    marginTop: '3px',
  },
  version: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    padding: 4,
    letterSpacing: '2px',
    color: '#363636',
    '&:hover': {
      '&:not(.Mui-selected)': {
        color: 'white',
      },
    },
  },
  recapthca: {
    width: theme.spacing(5),
  },
}))

function BottomCopyright() {
  const classes = useStyles()
  const { t } = useTranslation()
  const [currentYear] = React.useState(() => new Date().getFullYear())

  let recaptchaTooltip = (
    <Typography
      variant="body1"
      style={{
        color: 'white',
      }}
      align="left"
    >
      {t('main.footer.sections.copyright.helpText.1')}{' '}
      <a
        href="https://policies.google.com/privacy"
        style={{ textDecoration: 'none', color: 'cyan' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('main.footer.sections.copyright.helpText.2')}
      </a>{' '}
      {t('main.footer.sections.copyright.helpText.3')}{' '}
      <a
        href="https://policies.google.com/terms"
        style={{ textDecoration: 'none', color: 'cyan' }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('main.footer.sections.copyright.helpText.4')}
      </a>{' '}
      {t('main.footer.sections.copyright.helpText.5')}
    </Typography>
  )

  return (
    <Grid
      container
      directon="row"
      justifyContent="space-evenly"
      alignItems="center"
      className={classes.root}
    >
      <span className={classes.version}>{process.env.REACT_APP_VERSION}</span>
      <Grid item xs={12} sm="auto">
        {t('main.footer.sections.copyright.notice', { currentYear })}
      </Grid>
      <Grid item xs={12} sm="auto">
        <Grid container justifyContent="center" spacing={0}>
          {[
            {
              title: 'youtube',
              icon: YouTube,
              to: 'https://www.youtube.com/user/ExpressPublishingELT/featured',
            },
            {
              title: 'twitter',
              icon: LogoXWhite,
              to: 'https://twitter.com/ExpressELT',
            },
            {
              title: 'instagram',
              icon: Instagram,
              to: 'https://www.instagram.com/ExpressPublishing/',
            },
            {
              title: 'facebook',
              icon: Facebook,
              to: 'https://www.facebook.com/expresspublishing',
            },
          ].map((link) => (
            <Grid item key={link.to}>
              <IconButton
                href={link.to}
                target="_blank"
                rel="noopener noreferrer"
                component={Link}
                className={classes.button}
              >
                {link.title === 'twitter' ? (
                  <img
                    src={LogoXWhite}
                    alt="Twitter"
                    className={classes.twitterIcon}
                  />
                ) : (
                  <link.icon className={classes.icons} />
                )}
              </IconButton>
            </Grid>
          ))}
          <Grid item>
            <Tooltip
              title={recaptchaTooltip}
              interactive={true}
              placement="top"
            >
              <IconButton
                disableRipple
                style={{ width: '54px', height: '54px' }}
              >
                <img
                  src={RecaptchaIcon}
                  style={{ width: '24px', height: '24px' }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BottomCopyright
