import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import { useTranslation } from 'react-i18next'

Bottom.propTypes = {}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column wrap',
    textAlign: 'left',
    padding: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  title: {
    marginBottom: theme.spacing(2),
    ...theme.typography.h7,
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  overline: {
    ...theme.typography.overline,
    color: theme.palette.text.secondary,
  },
}))

function Bottom() {
  const classes = useStyles()
  const { t } = useTranslation()
  return (
    <nav className={classes.root}>
      <Typography className={classes.title}>
        {t('main.footer.sections.about.title')}
      </Typography>
      <Link className={classes.link} component={RouterLink} to="/privacy">
        <Typography className={classes.overline}>
          {t('main.footer.sections.about.privacyPolicy')}
        </Typography>
      </Link>
      <Link className={classes.link} component={RouterLink} to="/cookie-policy">
        <Typography className={classes.overline}>
          {t('main.footer.sections.about.CookiePolicy')}
        </Typography>
      </Link>
      <Link className={classes.link} component={RouterLink} to="/about-us">
        <Typography className={classes.overline}>
          {t('main.footer.sections.about.aboutUs')}
        </Typography>
      </Link>

      <Link className={classes.link} component={RouterLink} to="/terms">
        <Typography className={classes.overline}>
          {t('main.footer.sections.about.t&c')}
        </Typography>
      </Link>
      <Link
        className={classes.link}
        component={Link}
        target="_blank"
        rel="noopener noreferrer"
        href="https://stackshare.io/express-digibooks/express-digibooks#stack"
      >
        <Typography className={classes.overline}>
          {t('main.footer.sections.about.stack')}
        </Typography>
      </Link>
    </nav>
  )
}

export default Bottom
