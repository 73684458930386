import {
  SET_URL,
  SET_ACTIVITY,
  SET_TOC,
  SET_SPINE,
  SET_PACKAGE,
  SET_READ_TYPE,
  SET_ACTIVITIES,
} from './types'

const initialState = {
  url: null,
  package_name: null,
  activity: null,
  toc: null,
  spine: null,
  currentPackage: null,
  activities: [],
  readType: null,
}
const READ_TYPES = ['read', 'mission', 'assignment']
export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_PACKAGE:
      return { ...state, currentPackage: payload }
    case SET_READ_TYPE:
      return {
        ...state,
        readType: READ_TYPES.includes(payload.value)
          ? payload.value
          : 'Invalid Type',
      }
    case SET_ACTIVITIES:
      return {
        ...state,
        activities: [...payload],
      }
    case SET_URL:
      return { ...state, url: payload.value }
    case SET_ACTIVITY:
      return { ...state, activity: payload.value }
    case SET_TOC:
      return { ...state, toc: payload }
    case SET_SPINE:
      return { ...state, spine: payload }
    default:
      return state
  }
}
