import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import About from './About'
import Help from './Help'
import Logo from './Logo'
import Images from './Images'
import Copyright from './Copyright'

Bottom.propTypes = {}

const useStyles = makeStyles((theme) => ({
  root: {},
  upper: {
    flexGrow: 1,
    backgroundColor: '#e4e4e4',
    padding: theme.spacing(2, 3),
  },
  lower: {
    width: '100%',
  },
}))

function Bottom() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        className={classes.upper}
      >
        <Grid item>
          <Logo />
        </Grid>
        <Grid item>
          <About />
        </Grid>
        <Grid item>
          <Help />
        </Grid>
        <Grid item xs={12} sm="auto">
          <Images />
        </Grid>
      </Grid>
      <Copyright className={classes.lower} />
    </div>
  )
}

export default Bottom
