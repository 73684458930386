import {
  SET_FIELD,
  SET_STEPS,
  NEXT,
  VALIDATE_PASSWORD,
  PREV,
  CHECK_PASSWORD_MATCH,
  SET_EMAIL,
  SET_ERROR,
  SET_RETRIEVAL_METHOD,
} from './types'

const reducer = (state, { type, payload }) => {
  switch (type) {
    case VALIDATE_PASSWORD:
      return {
        ...state,
        passwordValid: /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{6,})/.test(
          payload.value
        ),
      }

    case CHECK_PASSWORD_MATCH:
      if (payload.password === payload.confirm) {
        return { ...state, passwordValidMatch: true }
      } else {
        return {
          ...state,
          passwordValidMatch: false,
        }
      }

    case SET_ERROR:
      return {
        ...state,
        error: { ...state.error, [payload.name]: payload.value },
      }

    case SET_FIELD:
      return {
        ...state,
        [payload.name]: payload.value,
      }

    case SET_EMAIL:
      return {
        ...state,
        email: payload.value,
      }

    case SET_RETRIEVAL_METHOD:
      return {
        ...state,
        retrievalMethod: payload.value,
      }

    case SET_STEPS:
      return {
        ...state,
        steps: payload.value,
      }

    case NEXT:
      if (state.currentStep === state.steps) return state
      return { ...state, currentStep: state.currentStep + 1 }

    case PREV:
      if (state.currentStep === 0) return state
      return { ...state, currentStep: state.currentStep - 1 }

    default:
      return state
  }
}
export { reducer }
