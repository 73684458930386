import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { useSelector } from 'react-redux'

import auth from 'api'
import { addToCondition } from 'helpers/api'

export const getSubSchoolSettings = async ({ queryKey }) => {
  const [, subSchoolId] = queryKey
  let condition = ''
  condition += '?per_page=0'
  condition = addToCondition(condition, subSchoolId, 'sub_school_id')
  const { data } = await auth({
    method: 'get',
    url: `auth/school/sub_school_settings${condition}`,
  })
  return data
}
export const useSubSchoolSettings = (
  id,
  { queryFilters, queryOptions } = {}
) => {
  return useQuery(
    ['subSchoolSettings', id, { ...queryFilters }],
    getSubSchoolSettings,
    {
      ...queryOptions,
    }
  )
}

export const createSubSchoolSetting = async ({ payload }) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/school/sub_school_settings`,
    data: {
      sub_school_setting: {
        ...payload,
      },
    },
  })
  return data
}
export const useCreateSubSchoolSetting = ({ queryOptions } = {}) => {
  return useMutation(createSubSchoolSetting, {
    ...queryOptions,
  })
}

export const updateSubSchoolSetting = async ({ id, payload }) => {
  const { data } = await auth({
    method: 'put',
    url: `auth/school/sub_school_settings/${id}`,
    data: {
      sub_school_setting: {
        ...payload,
      },
    },
  })
  return data
}

export const useUpdateSubSchoolSetting = ({ queryOptions } = {}) => {
  return useMutation(updateSubSchoolSetting, {
    ...queryOptions,
  })
}

export const useSubSchoolSetting = (id, queryOptions = {}) => {
  let subSchoolId = useSelector((state) => state.auth.currentSubSchool?.id)
  return useSubSchoolSettings(subSchoolId, {
    queryOptions: {
      select: React.useCallback(
        (data) => {
          return data.items.find((setting) => setting.setting_id === id)
        },
        [id]
      ),
      ...queryOptions,
    },
  })
}
