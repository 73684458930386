/* eslint-disable no-undef */
import React from 'react'
import clsx from 'clsx'
import {
  Paper,
  Grid,
  Typography,
  TextField,
  Divider,
  InputAdornment,
  IconButton,
  FormHelperText,
  FormControl,
  Input,
  InputLabel,
  FormControlLabel,
  Link,
  CircularProgress,
  Avatar,
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Lock, Person, Visibility, VisibilityOff } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import parseError from 'helpers/errorHelpers'
import { useLogin } from 'api/User/Users/queries'
import useNotification from 'hooks/useNotification'
import { ReactComponent as MFAIcon } from 'assets/svg/security_update_good_black.svg'
import GreenCheckbox from 'ui/list/BaseCheckbox'
import SaitaImage from 'assets/img/saita.png'
import Button from 'ui/Button'
import history from 'router'
import useStorage from 'hooks/useStorage'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    '& hr': {
      margin: theme.spacing(1, 0, 1),
    },
    '& .MuiGrid-item > .MuiSvgIcon-root': {
      color: theme.palette.text.secondary,
    },
  },
  form: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2, 5, 3),
    minHeight: '375px',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexGrow: 1,
    '& .MuiFormHelperText-root': {
      marginLeft: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 3, 1),
    },
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
    },
  },
  loginbutton: {
    marginTop: 20,
  },
  container: {
    width: '100%',
  },
  paper: {
    flexGrow: 1,
    position: 'relative',
    textAlign: 'center',
    // width: '576px',
    maxWidth: '576px',
    width: '100%',
  },
  logo: {
    position: 'absolute',
    ...theme.typography.mainlogo,
    backgroundColor: theme.palette.common.digicyan,
  },
  icon: {
    width: 70,
  },
  iconMFA: {
    fill: theme.palette.text.secondary,
  },
  link: {
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  margin3: {
    margin: theme.spacing(3),
  },
  afterDivider: {
    paddingBottom: theme.spacing(5),
  },
  error: {
    padding: theme.spacing(2),
    fontSize: 18,
    fontWeight: '400',
  },
}))

Login.propTypes = {}

function Login() {
  const classes = useStyles()

  const { t } = useTranslation()

  const { addNotification } = useNotification()

  const [remember, setRemember] = useStorage('digirememberme', '', 'session')

  const [showMFA, setShowMFA] = React.useState(false)

  const [showPassword, setShowPassword] = React.useState(false)

  const handleShowPassword = () => {
    setShowPassword((old) => !old)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const [local, setLocal] = React.useState({
    email: '',
    password: '',
    remember: remember,
    MFA: '',
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setLocal({ ...local, [name]: value })
  }

  const handleCheck = (event) => {
    setLocal({ ...local, [event.target.name]: event.target.checked })
    setRemember(event.target.checked)
  }

  const loginUser = useLogin({
    queryOptions: {
      onSuccess: (data) => {
        if (data?.reset_password) {
          history.push('/password-change')
        } else {
          history.push('/dashboard')
        }
      },
      onError: (error) => {
        if (error?.response?.data === 'MFA token missing') {
          setShowMFA(true)
        } else {
          addNotification({
            type: 'error',
            message: parseError(
              error,
              t('main.landing.sections.loginForm.messages.error')
            ),
          })
        }
      },
    },
  })

  const handleSubmit = async (e) => {
    // setShowMFA(false)
    e.preventDefault()
    let payload = {
      username: local.email,
      password: local.password,
      remember_me: local.remember,
      mfa_token: local.MFA,
    }
    if (!local.remember) delete payload.remember_me
    if (!local.MFA) delete payload.mfa_token
    loginUser.mutate(payload)
  }

  return (
    <div className={clsx(classes.root)}>
      <Paper className={clsx(classes.paper)} elevation={3}>
        <Avatar className={classes.logo}>
          <img src={SaitaImage} className={classes.icon} />
        </Avatar>

        <form className={clsx(classes.form)} onSubmit={handleSubmit}>
          <Grid
            container
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={2}>
              <Person />
            </Grid>
            <Grid item xs={9} style={{ flexGrow: '1' }}>
              <TextField
                value={local.email}
                name="email"
                label={t('main.landing.sections.loginForm.fields.username')}
                placeholder={t(
                  'main.landing.sections.loginForm.fields.username'
                )}
                fullWidth
                onChange={handleChange}
                helperText=" "
                required
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={2}>
              <Lock />
            </Grid>
            <Grid item xs={9} style={{ flexGrow: '1' }}>
              <FormControl className={classes.container}>
                <InputLabel htmlFor="form-password">
                  {t('main.landing.sections.loginForm.fields.password')}
                </InputLabel>
                <Input
                  id="form-password"
                  name="password"
                  value={local.password}
                  type={showPassword ? 'text' : 'password'}
                  onChange={handleChange}
                  aria-describedby="form-password-text"
                  placeholder={t(
                    'main.landing.sections.loginForm.fields.password'
                  )}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        color={showPassword ? 'primary' : 'secondary'}
                        disabled={!local.password}
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  fullWidth
                  required
                />
                <FormHelperText>
                  <Link
                    className={classes.link}
                    component={RouterLink}
                    to="/forgot"
                  >
                    <Typography component="span" variant="subtitle1">
                      {t(
                        'main.landing.sections.loginForm.prompts.forgotPassword'
                      )}
                    </Typography>
                  </Link>
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          {showMFA ? (
            <Grid
              container
              spacing={1}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <MFAIcon className={classes.iconMFA} />
              </Grid>
              <Grid item style={{ flexGrow: '1' }}>
                <TextField
                  value={local.email}
                  name="MFA"
                  label="MFA code"
                  placeholder="MFA code"
                  fullWidth
                  onChange={handleChange}
                  helperText=" "
                  required
                />
              </Grid>
            </Grid>
          ) : null}

          <Grid container spacing={1} alignItems="center">
            <Grid item>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={Boolean(local.remember)}
                    onChange={handleCheck}
                    name="remember"
                    color="primary"
                  />
                }
                label={
                  <Typography component="span" variant="subtitle1">
                    {/* {t('dfgdf', 'Remember me')} */}
                    {t('main.landing.sections.loginForm.checkBox.tick')}
                  </Typography>
                }
              />
            </Grid>
            <Divider />
          </Grid>
          {loginUser.isError && (
            <Typography variant="body1" color="error">
              {t('main.landing.sections.loginForm.messages.error')}
            </Typography>
          )}

          <Button
            variant="contained"
            color="cyan"
            rounded
            className={classes.loginbutton}
            type="submit"
          >
            {loginUser.isLoading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              t('common.actions.submit')
            )}
          </Button>
        </form>
        <Divider />
        <Grid
          container
          direction="column"
          justifyContent="center"
          className={classes.afterDivider}
        >
          <Grid item>
            <Typography
              component="span"
              variant="subtitle1"
              className={classes.margin3}
              style={{ display: 'block' }}
            >
              {/* {t('dfgdf', 'First time here? Click here to Sign Up')} */}
              {t('main.landing.sections.loginForm.prompts.signUpPrompt')}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              isLink
              rounded
              className={classes.button}
              to="/signup"
            >
              {t('main.landing.sections.loginForm.actions.signUp')}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default Login
