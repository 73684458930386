import {
  SET_USER,
  SET_ACCESS_TOKEN,
  SET_CURRENT_SCHOOL_YEAR,
  SET_CURRENT_SUB_SCHOOL,
  RESET_APP,
  SET_INDIVIDUAL_TEACHER,
} from './types'

import api from 'api'

export const setUser = (payload) => (dispatch) => {
  dispatch({
    type: SET_USER,
    payload: { value: payload },
  })
}

export const setAccessToken = (payload) => (dispatch) => {
  dispatch({
    type: SET_ACCESS_TOKEN,
    payload: { value: payload },
  })
}

export const resetApp = () => (dispatch) => {
  dispatch({
    type: RESET_APP,
  })
}

export const setCurrentSubSchool = (payload) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_SUB_SCHOOL,
    payload,
  })
}
export const setCurrentSchoolYear = (payload) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_SCHOOL_YEAR,
    payload,
  })
}
export const setCurrentIndTeacher = (payload) => (dispatch) => {
  dispatch({
    type: SET_INDIVIDUAL_TEACHER,
    payload,
  })
}

export const updateCurrentUser = () => async (dispatch, getState) => {
  let id = getState().auth.user.id
  try {
    let res2 = await api({
      method: 'get',
      url: `auth/user/users/${id}`,
    })
    dispatch({
      type: SET_USER,
      payload: { value: res2.data.user },
    })
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const refresh = () => async (dispatch) => {
  try {
    let res = await api({
      method: 'get',
      url: '/guest/user/auth/refresh',
    })
    dispatch({
      type: SET_ACCESS_TOKEN,
      payload: { value: res.data.auth.access_token },
    })
    return res.data.auth.access_token
  } catch (err) {
    console.log('error refresh', err)
  }
}
