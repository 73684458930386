import { useQuery, useMutation } from 'react-query'
import auth from 'api'
import { parseFilters, parsePage, parseSearch } from 'helpers/api'

export const searchTableLevels = async ({ queryKey }) => {
  const [, page, { filters = [], searchModels = {} } = {}] = queryKey

  let levelsSearch
  if (searchModels['Levels']) {
    levelsSearch = parseSearch('', searchModels, 'Levels')
  }
  const foundLevels = await getLevels({
    queryKey: [
      null,
      page,
      {
        filters: filters,
        search: levelsSearch,
      },
    ],
  })
  return foundLevels
}
export const useSearchTableLevels = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'searchLevels',
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    searchTableLevels,
    {
      refetchOnWindowFocus: false,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 10,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}

//  ----------------------------------------------

export const getLevels = async ({ queryKey }) => {
  const [, page, { filters = [], search } = {}] = queryKey

  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  if (search) condition += `&${search}`

  const { data } = await auth({
    method: 'get',
    url: `auth/school/levels${condition}`,
  })
  return data
}
export const useLevels = (
  page,
  { queryFilters = { filters: [] }, queryOptions } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    ['levels', paginationIsDisabled ? null : page + 1, { ...queryFilters }],
    getLevels,
    {
      keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 30,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}
// -----------------------------------
export const getLevel = async ({ queryKey }) => {
  const [, id] = queryKey
  const { data } = await auth({
    method: 'get',
    url: `auth/school/levels/${id}`,
  })
  return data
}
export const useLevel = (id, { queryOptions } = {}) => {
  return useQuery(['level', id], getLevel, {
    ...queryOptions,
  })
}
// -----------------------------------
export const createLevel = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/school/levels`,
    data: {
      level: {
        ...payload,
      },
    },
  })
  return data
}
export const useCreateLevel = ({ queryOptions } = {}) => {
  return useMutation(createLevel, {
    ...queryOptions,
  })
}
// -----------------------------------
export const updateLevel = async ({ id, payload }) => {
  const { data } = await auth({
    method: 'put',
    url: `auth/school/levels/${id}`,
    data: {
      level: {
        ...payload,
      },
    },
  })
  return data
}
export const useUpdateLevel = ({ queryOptions } = {}) => {
  return useMutation(updateLevel, {
    ...queryOptions,
  })
}
// -----------------------------------
export const deleteLevel = async (id) => {
  await auth({
    method: 'delete',
    url: `auth/school/levels/${id}`,
  })
}
export const useDeleteLevel = ({ queryOptions } = {}) => {
  return useMutation(deleteLevel, {
    ...queryOptions,
  })
}
