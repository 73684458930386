import { useQuery, useMutation } from 'react-query'
import { uniq } from 'lodash'

import auth from 'api'
import {
  parseFilters,
  parsePage,
  generateFilter,
  parseSearch,
} from 'helpers/api'
import { parseDateToSeconds } from 'helpers/dateHelpers'

import { getClasses } from 'api/School/Classes/queries'
import { getLessons, getLesson } from 'api/School/Lessons/queries'
import { getPackages } from 'api/Package/Packages/queries'
import { getPackage } from '../Packages/queries'
import {
  getTeacherClasses,
  getTeacherClass,
} from 'api/IndividualTeacher/Classes/queries'

// -----------------------------------
export const searchSchoolAssignments = async ({ queryKey }) => {
  const [, page, { filters = [], includeModels = [], searchModels = {} } = {}] =
    queryKey
  // let condition = ''
  const PER_PAGE = 50

  let foundPackages
  let packageIds = []

  let foundLessons
  let lessonIds = []

  let foundClasses
  let classIds = []

  let foundTeacherClasses
  let teacherClassIds = []

  let assignmentsFilters = [...filters]

  if (searchModels['Packages']) {
    let packageSearch = parseSearch('', searchModels, 'Packages')
    foundPackages = await getPackages({
      queryKey: [
        null,
        null,
        {
          search: packageSearch,
        },
      ],
    })
    packageIds = foundPackages?.items.map((pkg) => pkg.id)
    // assignmentsFilters = generateFilter(
    //   filters,
    //   packageIds,
    //   'Activity.Module.package_id'
    // )
  }

  if (searchModels['Lessons']) {
    let lessonSearch = parseSearch('', searchModels, 'Lessons')
    foundLessons = await getLessons({
      queryKey: [
        null,
        null,
        {
          search: lessonSearch,
        },
      ],
    })
    lessonIds = foundLessons?.items.map((lssn) => lssn.id)

    assignmentsFilters = generateFilter(
      assignmentsFilters,
      lessonIds,
      'lesson_id'
    )
  }

  if (searchModels['Classes']) {
    let classSearch = parseSearch('', searchModels, 'Classes')
    foundClasses = await getClasses({
      queryKey: [
        null,
        null,
        {
          search: classSearch,
        },
      ],
    })
    classIds = foundClasses?.items.map((cls) => cls.id)

    assignmentsFilters = generateFilter(
      assignmentsFilters,
      classIds,
      'class_id'
    )
  }

  if (searchModels['TeacherClasses']) {
    let classSearch = parseSearch('', searchModels, 'TeacherClasses')
    foundTeacherClasses = await getTeacherClasses({
      queryKey: [
        null,
        null,
        {
          search: classSearch,
        },
      ],
    })
    teacherClassIds = foundTeacherClasses?.items.map((cls) => cls.id)

    assignmentsFilters = generateFilter(
      assignmentsFilters,
      teacherClassIds,
      'class_id'
    )
  }

  let includeModelsAssignments = []
  if (!searchModels['Packages'] && includeModels.includes('Packages')) {
    includeModelsAssignments.push('Packages')
  }
  if (!searchModels['Lessons'] && includeModels.includes('Lessons')) {
    includeModelsAssignments.push('Lessons')
  }
  if (!searchModels['Classes'] && includeModels.includes('Classes')) {
    includeModelsAssignments.push('Classes')
  }
  if (
    !searchModels['TeacherClasses'] &&
    includeModels.includes('TeacherClasses')
  ) {
    includeModelsAssignments.push('TeacherClasses')
  }

  let assignmentSearch
  if (searchModels['Assignments']) {
    assignmentSearch = parseSearch('', searchModels, 'Assignments')
  }

  if (searchModels['Packages'] && foundPackages.total_items === 0)
    return { items: [] }
  if (searchModels['Lessons'] && foundLessons.total_items === 0)
    return { items: [] }
  if (searchModels['Classes'] && foundClasses.total_items === 0)
    return { items: [] }
  if (searchModels['TeacherClasses'] && foundTeacherClasses.total_items === 0)
    return { items: [] }

  let foundAssignments

  if (searchModels['Packages']) {
    if (PER_PAGE > foundPackages.items.length) {
      assignmentsFilters = generateFilter(
        assignmentsFilters,
        packageIds,
        'Activity.Module.package_id'
      )
      foundAssignments = await getAssignments({
        queryKey: [
          null,
          page,
          {
            filters: assignmentsFilters,
            includeModels: includeModelsAssignments,
            search: assignmentSearch,
          },
        ],
      })
    } else {
      let totalPages = Math.ceil(foundPackages.items.length / PER_PAGE)

      for (let page = 0; page < totalPages; page++) {
        assignmentsFilters = [...filters]

        if (lessonIds) {
          assignmentsFilters = generateFilter(
            assignmentsFilters,
            lessonIds,
            'lesson_id'
          )
        }

        if (teacherClassIds) {
          assignmentsFilters = generateFilter(
            assignmentsFilters,
            teacherClassIds,
            'class_id'
          )
        }
        if (classIds) {
          assignmentsFilters = generateFilter(
            assignmentsFilters,
            classIds,
            'class_id'
          )
        }
        if (lessonIds) {
          assignmentsFilters = generateFilter(
            assignmentsFilters,
            lessonIds,
            'lesson_id'
          )
        }

        let condition = ''

        condition = parsePage(null, condition)
        condition = parseFilters(filters, condition)

        let packagesSlicedArray = packageIds.slice(
          page * PER_PAGE,
          page * PER_PAGE + PER_PAGE
        )

        if (packagesSlicedArray?.length) {
          assignmentsFilters = generateFilter(
            assignmentsFilters,
            packagesSlicedArray,
            'Activity.Module.package_id'
          )
        }

        let currentFoundAssignments = await getAssignments({
          queryKey: [
            null,
            page,
            {
              filters: assignmentsFilters,
              includeModels: includeModelsAssignments,
              search: assignmentSearch,
            },
          ],
        })

        if (page === 0) {
          foundAssignments = currentFoundAssignments
        } else {
          foundAssignments.items = [
            ...foundAssignments.items,
            ...currentFoundAssignments.items,
          ]
          foundAssignments.total_items =
            foundAssignments.total_items + currentFoundAssignments.total_items
        }
      }
    }
  } else {
    foundAssignments = await getAssignments({
      queryKey: [
        null,
        page,
        {
          filters: assignmentsFilters,
          includeModels: includeModelsAssignments,
          search: assignmentSearch,
        },
      ],
    })
  }

  let assignmentsWithData = {
    ...foundAssignments,
    items: foundAssignments.items.reduce((acc, assgnmnt) => {
      let assignment = assgnmnt
      if (searchModels['Lessons'] && foundLessons?.items.length) {
        let idx = foundLessons.items.findIndex(
          (lsn) => lsn.id === assgnmnt.lesson_id
        )
        if (idx !== -1) {
          assignment.Lesson = foundLessons.items[idx]
          assignment.Class = foundLessons.items[idx].Class
        }
      }
      if (searchModels['Classes'] && foundClasses?.items.length) {
        let idx = foundClasses.items.findIndex(
          (cls) => cls.id === assgnmnt.class_id
        )
        if (idx !== -1) {
          assignment.Class = foundClasses.items[idx]
        }
      }
      if (searchModels['Packages'] && foundPackages?.items.length) {
        let idx = foundPackages.items.findIndex(
          (pData) => pData.id === assignment.Activity.Module.package_id
        )
        if (idx !== -1) {
          assignment.Package = foundPackages.items[idx]
        }
      }
      if (searchModels['TeacherClasses'] && foundTeacherClasses?.items.length) {
        let idx = foundTeacherClasses.items.findIndex(
          (classData) => classData.id === assignment.class_id
        )
        if (idx !== -1) {
          assignment.Class = foundTeacherClasses.items[idx]
        }
      }
      return [...acc, assignment]
    }, []),
  }
  return assignmentsWithData
}
export const useSearchAssignments = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'searchAssignments',
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    searchSchoolAssignments,
    {
      refetchOnWindowFocus: false,
      // keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 10,
      ...queryOptions,
    }
  )

  // React.useEffect(() => {
  //   if (data?.total_pages > data?.current_page && !paginationIsDisabled) {
  //     queryClient.prefetchQuery(
  //       ['searchAssignments', page + 2, { ...queryFilters }],
  //       searchSchoolAssignments
  //     )
  //   }
  // }, [data, page, queryClient, queryFilters, paginationIsDisabled])

  return { data, ...rest }
}

// -----------------------------------
export const getAssignments = async ({ queryKey }) => {
  const [, page, { filters = [], includeModels = [], search } = {}] = queryKey
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  if (search) condition += `&${search}`
  const { data: assignments } = await auth({
    method: 'get',
    url: `auth/pkg/assignments${condition}`,
  })
  let assignmentsWithData

  let teacherClasses = assignments?.items.map(
    (assignment) => assignment.class_id
  )
  let allTeacherClassesData
  if (includeModels?.includes('TeacherClasses')) {
    if (teacherClasses.length) {
      let uniqueClassesIds = uniq(teacherClasses)
      const classesFilters = generateFilter([], uniqueClassesIds, 'id')
      allTeacherClassesData = await getTeacherClasses({
        queryKey: [
          null,
          1,
          {
            filters: classesFilters,
          },
        ],
      })
    }
  }

  let lessons = assignments?.items.map((assignment) => assignment.lesson_id)
  let allLessonsData

  if (includeModels?.includes('Lessons')) {
    if (lessons.length) {
      let uniqueLessonsIds = uniq(lessons)
      const lessonFilters = generateFilter([], uniqueLessonsIds, 'id')
      allLessonsData = await getLessons({
        queryKey: [
          null,
          1,
          {
            filters: lessonFilters,
          },
        ],
      })
    }
  }

  let classes = assignments?.items.map((assignment) => assignment.class_id)
  let allClassesData

  if (includeModels?.includes('Classes')) {
    if (classes.length) {
      let uniqueClassesIds = uniq(classes)
      const classFilters = generateFilter([], uniqueClassesIds, 'id')
      allClassesData = await getClasses({
        queryKey: [
          null,
          1,
          {
            filters: classFilters,
          },
        ],
      })
    }
  }

  let packages = assignments?.items.map(
    (assignment) => assignment.Activity.Module.package_id
  )
  let allPackagesData
  if (includeModels?.includes('Packages')) {
    if (packages.length) {
      let uniquePackageIds = uniq(packages)
      const lessonFilters = generateFilter([], uniquePackageIds, 'id')
      allPackagesData = await getPackages({
        queryKey: [
          null,
          1,
          {
            filters: lessonFilters,
          },
        ],
      })
    }
  }
  assignmentsWithData = {
    ...assignments,
    items: assignments.items.reduce((acc, assgnmnt) => {
      let assignment = { ...assgnmnt }
      if (
        includeModels?.includes('TeacherClasses') &&
        allTeacherClassesData.items.length
      ) {
        let idx = allTeacherClassesData.items.findIndex(
          (cls) => cls.id === assgnmnt.class_id
        )
        if (idx !== -1) {
          assignment.Class = allTeacherClassesData.items[idx]
        }
      }
      if (includeModels?.includes('Lessons') && allLessonsData.items.length) {
        let idx = allLessonsData.items.findIndex(
          (lsn) => lsn.id === assgnmnt.lesson_id
        )
        if (idx !== -1) {
          assignment.Lesson = allLessonsData.items[idx]
          assignment.Class = allLessonsData.items[idx].Class
        }
      }
      if (includeModels?.includes('Classes') && allClassesData.items.length) {
        let idx = allClassesData.items.findIndex(
          (cls) => cls.id === assgnmnt.class_id
        )
        if (idx !== -1) {
          assignment.Class = allClassesData.items[idx]
        }
      }
      if (includeModels?.includes('Packages') && allPackagesData.items.length) {
        let idx = allPackagesData.items.findIndex(
          (pData) => pData.id === assignment.Activity.Module.package_id
        )
        if (idx !== -1) {
          assignment.Package = allPackagesData.items[idx]
        }
      }
      return [...acc, assignment]
    }, []),
  }
  return assignmentsWithData
}
export const useSchoolAssignments = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'schoolAssignments',
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    getAssignments,
    {
      keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 60,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}
// -----------------------------------
export const getAssignment = async ({ queryKey }) => {
  const [, id, { includeModels = [] }] = queryKey
  const { data: assignment } = await auth({
    method: 'get',
    url: `auth/pkg/assignments/${id}`,
  })
  let assignmentWithData = { ...assignment }
  if (includeModels?.includes('Lessons')) {
    let lessonId = assignment?.lesson_id
    let allLessonData = await getLesson({
      queryKey: [null, lessonId, { includeModels: ['Users', 'Levels'] }],
    })
    assignmentWithData.Lesson = allLessonData
  }
  if (includeModels?.includes('Classes')) {
    let classId = assignment?.class_id
    let classData = await getTeacherClass({
      queryKey: [null, classId, {}],
    })
    assignmentWithData.Class = classData
  }

  if (includeModels?.includes('Packages')) {
    let packageId = assignment?.Activity.Module.package_id
    let packageData = await getPackage({
      queryKey: [null, packageId],
    })
    assignmentWithData.Package = packageData
  }

  return assignmentWithData
}
export const useSchoolAssignment = (
  id,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  return useQuery(
    ['schoolAssignment', id, { ...queryFilters }],
    getAssignment,
    {
      ...queryOptions,
    }
  )
}
// -----------------------------------
export const createAssignment = async ({ payload }) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/pkg/assignments`,
    data: {
      ...payload,
      deadline: parseInt(parseDateToSeconds(payload.deadline)),
    },
  })
  return data
}
export const useCreateSchoolAssignment = ({ queryOptions } = {}) => {
  return useMutation(createAssignment, {
    ...queryOptions,
  })
}
// -----------------------------------
export const updateAssignment = async ({ id, payload }) => {
  const { data } = await auth({
    method: 'put',
    url: `auth/pkg/assignments/${id}`,
    data: {
      ...payload,
      deadline: parseInt(parseDateToSeconds(payload.deadline)),
    },
  })
  return data
}
export const useUpdateAssignment = ({ queryOptions } = {}) => {
  return useMutation(updateAssignment, {
    ...queryOptions,
  })
}
// -----------------------------------
export const deleteAssignment = async ({ id }) => {
  await auth({
    method: 'delete',
    url: `auth/pkg/assignments/${id}`,
  })
}
export const useDeleteAssignment = ({ queryOptions } = {}) => {
  return useMutation(deleteAssignment, {
    ...queryOptions,
  })
}
