import React from 'react'
import PropTypes from 'prop-types'

const NotificationContext = React.createContext()

NotificationContext.displayName = 'NotificationContext'

let counter = 0
const getUniqueId = () => `id-${counter++}`

const NotificationContextProvider = ({ children }) => {
  const [notifications, setNotifications] = React.useState([])

  const addNotification = (options) => {
    let newNotification = { ...options, key: getUniqueId() }
    setNotifications((prev) => [...prev, newNotification])
  }

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotifications,
        addNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}
NotificationContextProvider.propTypes = {
  children: PropTypes.node,
}

export { NotificationContext, NotificationContextProvider }
