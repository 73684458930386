import { uniq } from 'lodash'
import { useMutation, useQuery } from 'react-query'

import auth from 'api'
import {
  addToCondition,
  generateFilter,
  parseFilters,
  parsePage,
} from 'helpers/api'

import { getLevels } from 'api/School/Levels/queries'
import { getUsers } from 'api/User/Users/queries'
import { getAssignments } from 'api/Package/SchoolAssignments/queries'
import { getCompletedAttempts } from 'api/Package/Attempts/queries'

// ---------------------------------------------------------
export const getClassUsers = async ({ queryKey }) => {
  const [, classId, page, { filters = [], includeModels = [] } = {}] = queryKey

  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  if (classId) {
    condition = addToCondition(condition, classId, 'class_id')
  }
  const { data: classUsers } = await auth({
    method: 'get',
    url: `auth/school/class_users${condition}`,
  })

  let usersData
  let classUserWithData = classUsers
  if (includeModels?.includes('Users')) {
    const userIDs = classUsers?.items.map((usr) => usr.user_id)
    const uniqIds = uniq(userIDs)
    if (uniqIds.length) {
      usersData = await getUsers({
        queryKey: [
          null,
          null,
          {
            users: uniqIds,
            filters: [
              {
                by: 'sub_school_id',
                value: classUsers?.items[0].Class.SchoolYear.sub_school_id,
              },
            ],
          },
        ],
      })
    } else return
    classUserWithData = {
      ...classUserWithData,
      items: classUserWithData.items.reduce((acc, schUsr) => {
        let idx = usersData.items.findIndex((usr) => usr.id === schUsr.user_id)
        if (idx !== -1)
          return [...acc, { ...schUsr, User: { ...usersData.items[idx] } }]
        else return acc
      }, []),
    }
  }

  let levelData
  if (includeModels?.includes('Levels')) {
    const levelsIDs = classUsers?.items.map((usr) => usr.Class.level_id)
    let uniqueIds = uniq(levelsIDs)
    if (uniqueIds.length) {
      const levelFilters = generateFilter([], uniqueIds, 'id')
      levelData = await getLevels({
        queryKey: [null, null, { filters: levelFilters }],
      })
    } else return
    classUserWithData = {
      ...classUserWithData,
      items: classUserWithData.items.reduce((acc, usr) => {
        let idx = levelData.items.findIndex(
          (lvl) => lvl.id === usr.Class.level_id
        )
        if (idx !== -1)
          return [...acc, { ...usr, Level: { ...levelData.items[idx] } }]
        else return acc
      }, []),
    }
  }

  let assignmentsData
  if (includeModels?.includes('Assignments')) {
    const assignmentFilters = generateFilter([], classId, 'class_id')
    assignmentsData = await getAssignments({
      queryKey: [null, null, { filters: assignmentFilters }],
    })
    let attemptsData = []
    if (assignmentsData?.items.length) {
      const userIDs = classUsers?.items.map((usr) => usr.user_id)
      const uniqUserIds = uniq(userIDs)

      const activityIDs = assignmentsData?.items.map(
        (assignment) => assignment.activity_id
      )
      const uniqactivityIds = uniq(activityIDs)

      attemptsData = await getCompletedAttempts({
        queryKey: [
          null,
          classUsers?.items[0].Class.SchoolYear.sub_school_id,
          null,
          {
            filters: [
              {
                by: 'created[gte]',
                value: classUsers?.items[0].Class?.SchoolYear?.starts,
              },
              {
                by: 'created[lte]',
                value: classUsers?.items[0].Class?.SchoolYear?.ends,
              },
            ],
            users: uniqUserIds,
            activities: uniqactivityIds,
          },
        ],
      })
    }

    classUserWithData = {
      ...classUserWithData,
      items: classUserWithData.items.reduce((acc, usr) => {
        let idx = attemptsData.findIndex((att) => att.user_id === usr.user_id)

        if (idx !== -1)
          return [
            ...acc,
            {
              ...usr,
              Attempts: {
                totalAssignments: assignmentsData.total_items,
                attempts: attemptsData[idx].count,
              },
            },
          ]
        else
          return [
            ...acc,
            {
              ...usr,
              Attempts: {
                totalAssignments: assignmentsData.total_items,
                attempts: 0,
              },
            },
          ]
      }, []),
    }
  }
  return classUserWithData
}

export const useClassUsers = (
  classId,
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  return useQuery(
    [
      'classUsers',
      classId,
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    getClassUsers,
    {
      keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 60,
      ...queryOptions,
    }
  )
}
// -----------------------------------
export const createClassUser = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/school/class_users`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useCreateClassUser = ({ queryOptions } = {}) => {
  return useMutation(createClassUser, {
    ...queryOptions,
  })
}
// -----------------------------------

export const createUserClasses = async ({ classes, id: userId }) => {
  let promiseArray
  let res
  if (classes?.length) {
    promiseArray = classes.map((classId) =>
      createClassUser({
        class_user: {
          user_id: userId,
          class_id: classId,
        },
      })
    )
    res = await Promise.allSettled(promiseArray)
  }
  return res
}
export const useCreateUserClasses = ({ queryOptions } = {}) => {
  return useMutation(createUserClasses, {
    ...queryOptions,
  })
}
// -----------------------------------

export const createClassUsers = async ({ users, id: classId }) => {
  let promiseArray
  let res
  if (users?.length) {
    promiseArray = users.map((userId) =>
      createClassUser({
        class_user: {
          user_id: userId,
          class_id: classId,
        },
      })
    )
    res = await Promise.allSettled(promiseArray)
  }
  return res
}
export const useCreateClassUsers = ({ queryOptions } = {}) => {
  return useMutation(createClassUsers, {
    ...queryOptions,
  })
}
// ---------------------------------------------------------
export const enrollClassUser = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `/auth/school/class_users/enroll`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useEnrollClassUser = ({ queryOptions } = {}) => {
  return useMutation(enrollClassUser, {
    ...queryOptions,
  })
}
// ---------------------------------------------------------

export const deleteClassUser = async (id) => {
  await auth({
    method: 'delete',
    url: `auth/school/class_users/${id}`,
  })
}
export const useDeleteClassUser = ({ queryOptions } = {}) => {
  return useMutation(deleteClassUser, {
    ...queryOptions,
  })
}
