import { QueryClient, QueryCache } from 'react-query'

export const queryCache = new QueryCache({
  onError: () => {},
})

export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: true,
      // staleTime: 30000,
    },
  },
})

queryClient.setQueryDefaults('subSchoolSettings', {
  staleTime: Infinity,
  cacheTime: Infinity,
})
queryClient.setQueryDefaults('consents', {
  staleTime: Infinity,
  cacheTime: Infinity,
})
queryClient.setQueryDefaults('staticContent', {
  staleTime: Infinity,
  cacheTime: Infinity,
})
