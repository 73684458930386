export default (roleId) => {
  switch (roleId) {
    case 1000:
    case '1000':
      return 'Admin'
    case 1001:
    case '1001':
      return 'Member'
    case 1002:
    case '1002':
      return 'Student'
    case 1003:
    case '1003':
      return 'Teacher'
    case 1004:
    case '1004':
      return 'School Manager'
    case 1005:
    case '1005':
      return 'Code Manager'
    case 1006:
    case '1006':
      return 'Tester'
    case 1007:
    case '1007':
      return 'Guardian'
    case 1008:
    case '1008':
      return 'Moderator'
    case 1009:
    case '1009':
      return 'Sales'
    case 1010:
    case '1010':
      return 'Project Manager'
    case 1011:
    case '1011':
      return 'School Master'
    case 1012:
    case '1012':
      return 'Editor'
    case 1013:
    case '1013':
      return 'Individual Teacher'
    default:
      return 'Unknown role'
  }
}
