import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Toolbar, LinearProgress, Container } from '@material-ui/core'
import ReaderAppbar from './reader-appbar.component'
import ReaderFrame from './reader-frame.component'
import { useReaderContext } from './context/reader.context'

const drawerWidth = 300

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    // // alignItems: 'center',
    alignItems: 'center',
    // // paddingTop: theme.spacing(1),
    // // paddingBottom: theme.spacing(2),
  },
  menuButton: {},
  hide: {
    display: 'none',
  },
  main: {},
  mainShift: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  title: {},
  header: {
    fontSize: 30,
    fontWeight: 300,
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iframe: {
    width: 1060,
    height: `calc(100vh - ${62}px)`,
  },
  iframeShift: {},
}))

function ReaderHTML5() {
  const classes = useStyles()

  const {
    iRef,
    html5: { url },
    info: { title },
  } = useReaderContext()

  const [hasLoaded, setHasLoaded] = React.useState(false)

  const handleLoad = () => {
    setHasLoaded(true)
  }

  return (
    <div className={classes.root}>
      <ReaderAppbar hasDrawer={false} hideControls={true} title={title} />
      <Container maxWidth="xl" disableGutters>
        <Toolbar />
        {!hasLoaded ? <LinearProgress style={{ width: '1920px' }} /> : null}
        <ReaderFrame
          ref={iRef}
          variant={'html5'}
          loaded={hasLoaded}
          frameSrc={url}
          onLoad={handleLoad}
        />
      </Container>
    </div>
  )
}

ReaderHTML5.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  availableActivities: PropTypes.array,
  currentActivity: PropTypes.any,
  currentIndex: PropTypes.any,
  setActivity: PropTypes.func,
  setIndex: PropTypes.func,
}

export default ReaderHTML5
