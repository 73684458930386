import { useQuery, useMutation } from 'react-query'

import auth from 'api'
import { addToCondition, parseFilters, parsePage } from 'helpers/api'

// -----------------------------------
export const getCrossplatformPackages = async ({ queryKey }) => {
  const [, page, { filters = [], packages = [] } = {}] = queryKey
  let condition = ''
  condition = parsePage(page, condition)
  condition = parseFilters(filters, condition)
  if (packages?.length) {
    condition = addToCondition(condition, packages, 'package_id')
  }
  const { data: crossplatformPackages } = await auth({
    method: 'get',
    url: `auth/pkg/crossplatforms${condition}`,
  })

  return crossplatformPackages
}
export const useCrossplatformPackages = (
  page,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  const paginationIsDisabled = page == null

  const { data, ...rest } = useQuery(
    [
      'crossplatformPackages',
      paginationIsDisabled ? null : page + 1,
      { ...queryFilters },
    ],
    getCrossplatformPackages,
    {
      keepPreviousData: paginationIsDisabled ? false : true,
      staleTime: paginationIsDisabled ? 0 : 1000 * 60 * 1,
      ...queryOptions,
    }
  )

  return { data, ...rest }
}
// -----------------------------------
export const getCrossplatformPackage = async ({ queryKey }) => {
  const [, id] = queryKey
  const { data: crossplatformPackage } = await auth({
    method: 'get',
    url: `auth/pkg/crossplatforms/${id}`,
  })

  return crossplatformPackage
}
export const useCrossplatformPackage = (
  id,
  { queryFilters = {}, queryOptions = {} } = {}
) => {
  return useQuery(
    ['crossplatformPackage', id, { ...queryFilters }],
    getCrossplatformPackage,
    {
      ...queryOptions,
    }
  )
}
// -----------------------------------
export const createCrossplatformPackage = async (payload) => {
  const { data } = await auth({
    method: 'post',
    url: `auth/pkg/crossplatforms`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useCreateCrossplatformPackage = ({ queryOptions } = {}) => {
  return useMutation(createCrossplatformPackage, {
    ...queryOptions,
  })
}
// -----------------------------------
export const updateCrossplatformPackage = async ({ id, payload }) => {
  const { data } = await auth({
    method: 'put',
    url: `auth/pkg/crossplatforms/${id}`,
    data: {
      ...payload,
    },
  })
  return data
}
export const useUpdateCrossplatformPackage = ({ queryOptions } = {}) => {
  return useMutation(updateCrossplatformPackage, {
    ...queryOptions,
  })
}
// -----------------------------------
export const deleteCrossplatformPackage = async (id) => {
  const data = await auth({
    method: 'delete',
    url: `auth/pkg/crossplatforms/${id}`,
  })
  return data
}
export const useDeleteCrossplatformPackage = ({ queryOptions } = {}) => {
  return useMutation(deleteCrossplatformPackage, {
    ...queryOptions,
  })
}
