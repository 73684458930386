import { createSelector } from 'reselect'
import isEmpty from 'lodash/isEmpty'

import getRoleTitle from 'helpers/getRoleTitle'

export const currentUser = (state) => state.auth.user
export const userEmpty = (state) => isEmpty(state.auth.user)
export const isAuthenticated = (state) => !!state.auth.user.id

const accessToken = (state) => state.auth.accessToken

export const isAuthenticatedSelector = createSelector(
  [userEmpty],
  (notLoggedIn) => {
    return !notLoggedIn
  }
)

accessToken

export const accessTokenSelector = createSelector(
  [accessToken],
  (token) => token
)

export const userRoleSelector = createSelector(
  [currentUser, userEmpty],
  (user, notLoggedIn) => {
    if (notLoggedIn) return undefined
    else {
      if (user.User_roles && user.User_roles.length > 0)
        return getRoleTitle(user.User_roles[0].role_id)
    }
    return undefined
  }
)
